import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import config from 'src/global-config'
import BtnPrimary from 'component/BtnPrimary'
import {
  hideGlobalLoading,
  hideImportUsers,
  hideProcessingModal,
  setCountUserAssignedToClient,
  setCountUserAssignedToFolder,
  setCountUserEnrolled,
  showGlobalLoading,
  showProcessingModal,
} from '../../actions'
import { loadListCourse } from 'container/Home/actions'
import { useSelector } from 'react-redux'
import { makeSelectListCourse } from 'container/Home/selectors'
import { showError, showSuccess } from 'utils/notification'
import CSVReader from 'react-csv-reader'
import { signUpUser } from 'utils/hooks'
import { loadListUser } from 'container/Admin/actions'
import { fileUrl } from 'utils/constants'
import { saveAs } from 'file-saver'
import { get } from 'lodash'
import { getAccessToken } from 'utils/request'
import { getUserByEmail } from 'container/Home/saga'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData } from 'utils/helper'
import stringify from 'qs/lib/stringify'
import { getGroupByLevel } from '../../../Admin/screens/ClassManagement'

const ImportUsersModal = ({ dispatch, courseSelected, showImportUsers }) => {
  const courses = useSelector(makeSelectListCourse())
  const [csvData, setCsvData] = useState(null)
  const [listSchedules, setListSchedules] = useState(null)
  const [listClients, setListClients] = useState(null)
  const [foldersData, setFolders] = useState(null)

  useEffect(() => {
    const getFolders = async () => {
      try {
        const params = stringify({
          page: 1,
          per_page: 10000,
          ignore_per_page: true,
          order_by: 'id desc',
        })
        const res = await axiosInstance.get(`/api/folders?${params}`)
        const data = getResponseData(res)

        setFolders(data)
      } catch (error) {
        setFolders([])
        console.log('error', error)
      }
    }

    getFolders()
  }, [])

  useEffect(() => {
    const fetchSchedules = async (courseId) => {
      try {
        let requestURL = `${config.baseUrl}/api/lms/courses/channels/${courseId}/schedules?order_by=id%20asc&per_page=1000&ignore_per_page=true`
        const res = await fetch(requestURL, { method: 'GET' })
        const data = await res.json()

        if (Array.isArray(data.data)) {
          return data.data
        }
      } catch (error) {
        dispatch(hideGlobalLoading())
        return showError('Loading schedules error. Please refresh your page!')
      }
    }

    const fetchSchedulesByCourse = async () => {
      if (courses) {
        const requests = []
        for (let i = 0; i < courses.length; i++) {
          requests.push(fetchSchedules(get(courses, `[${i}].id`)))
        }
        const list = await Promise.all(requests)
        dispatch(hideGlobalLoading())
        setListSchedules(list)
      }
    }

    if (courses) {
      dispatch(showGlobalLoading())
      fetchSchedulesByCourse()
    }
  }, [courses])

  const defaultFilter = {
    page: 1,
    per_page: 1000,
    ignore_per_page: true,
    order_by: 'id desc',
  }

  const getClients = async (searchValue) => {
    try {
      const params = stringify(
        searchValue
          ? { ...defaultFilter, name: searchValue }
          : { ...defaultFilter }
      )
      const res = await axiosInstance.get(`/api/clients?${params}`)
      const data = getResponseData(res)
      if (!searchValue) {
        setListClients(data)
      } else {
        return data
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    dispatch(loadListCourse({ admin: true, filter: 'sequence' }))
    getClients()
  }, [])

  const handleCancel = () => {
    dispatch(hideImportUsers())
  }

  const assigneStudent = async (userId, client) => {
    try {
      const res = await axiosInstance.put('/api/clients/users', {
        user_id: userId,
        client_id: get(client, 'id'),
      })
      const data = getResponseData(res)

      if (data?.id) {
        dispatch(setCountUserAssignedToClient())
        return data.id
      }
    } catch (error) {
      console.log('~ error', error)
    }
  }

  const postEnroll = async (channelID, scheduleID, userID, partnerID) => {
    const requestURL = `${config.baseUrl}/api/lms/courses/channels/partners`
    try {
      const request = await fetch(requestURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          channel_id: channelID,
          partner_id: partnerID,
          user_id: userID,
          schedule_id: scheduleID,
          is_send: true,
        }),
      })

      const data = await request.json()
      if (data?.data?.id) {
        dispatch(setCountUserEnrolled())
      }
      return data?.data?.id
    } catch (err) {
      console.log('err', err)
    }
  }

  const assignGroup = async (folderID, userID) => {
    try {
      let payload = {
        folder_id: folderID,
        user_id: userID,
      }
      const data = await axiosInstance.put(
        `/api/users/${userID}/folders`,
        payload
      )

      if (data?.status === 200) {
        dispatch(setCountUserAssignedToFolder())
        return data?.status === 200
      }
    } catch (error) {
      console.log('error', error)
      return false
    }
  }

  const handleImport = async () => {
    if (!csvData) {
      return showError('Please import the csv file first!')
    }

    dispatch(showProcessingModal())
    const requests = []
    for (let i = 0; i < csvData.length; i++) {
      const name = get(csvData, `[${i}][0]`, '')
      const email = get(csvData, `[${i}][1]`, '').toLowerCase()
      const password = get(csvData, `[${i}][2]`, '')
      const isAdmin =
        get(csvData, `[${i}][5]`, '')?.trim()?.toLocaleLowerCase() === 'admin'
      if (get(csvData, `[${i}][0]`)) {
        requests.push(
          signUpUser(
            {
              name,
              login: email,
              password,
              isAdmin,
            },
            dispatch
          )
        )
      }
    }
    const data = await Promise.all(requests)

    // Enroll user to course with schedule_id
    let request2 = []
    // Handle assign student client
    let request3 = []
    // Handle assign student
    let request4 = []

    for (let i = 0; i < data.length; i++) {
      const name = get(csvData, `[${i}][0]`, '')
      const email = get(csvData, `[${i}][1]`).toLowerCase()
      const courseName = get(csvData, `[${i}][3]`).trim().toLowerCase()
      const clientName = get(csvData, `[${i}][6]`, '').trim()

      const client =
        listClients &&
        listClients.find(
          (item) =>
            item.name.trim().toLowerCase() === clientName.trim().toLowerCase()
        )

      const clientCourses = get(client, 'channel_clients', null)
      const classCode = get(csvData, `[${i}][4]`).trim().toLowerCase()
      const password = get(csvData, `[${i}][2]`, '')
      const isAdmin =
        get(csvData, `[${i}][5]`, '')?.trim()?.toLocaleLowerCase() === 'admin'

      if (csvData[i] && email) {
        const courseId = get(
          courses &&
            courses.find(
              (course) => course.name.trim().toLowerCase() === courseName
            ),
          'id'
        )

        let scheduleId = 0

        listSchedules &&
          listSchedules.find((list) => {
            const schedule =
              Array.isArray(list) &&
              list.find(
                (item) => item.class_code.trim().toLowerCase() === classCode
              )
            if (schedule) {
              return (scheduleId = get(schedule, 'id', 0))
            }
            return null
          })

        const groupsValue = get(csvData, `[0][7]`, '').trim()
        const groups = groupsValue.split(',')

        if (data[i]) {
          const userId = get(data, `[${i}].id`)
          const partnerId = get(data, `[${i}].partner_id`)

          // assigne student to group
          if (groupsValue && groups) {
            for (let i = 0; i < groups.length; i++) {
              const folder = groups[i]

              const folders = folder.split('>')
              if (folders.length === 1) {
                const child = folders[0]
                const childItem = foldersData?.find(
                  (item) =>
                    item.name.trim().toLowerCase() ===
                    child.trim().toLowerCase()
                )
                if (childItem) {
                  request4.push(assignGroup(childItem?.id, userId))
                }
              } else {
                const clientName = folders[0]
                const childName = folders[1]
                const clientItem = foldersData.find(
                  (item) =>
                    item.name.trim().toLowerCase() ===
                    clientName.trim().toLowerCase()
                )
                const groupLevel2 = getGroupByLevel(
                  foldersData,
                  2,
                  clientItem?.id
                )

                const childItem = foldersData?.filter(
                  (item) =>
                    item?.level !== 1 &&
                    item.name.trim().toLowerCase() ===
                      childName.trim().toLowerCase()
                )

                if (childItem?.length === 1) {
                  request4.push(assignGroup(childItem[0]?.id, userId))
                } else {
                  const exactFolder = childItem?.find((item) => {
                    const findParentFolder = groupLevel2?.find(
                      (item2) => item2?.id === item.parent_id
                    )
                    if (findParentFolder) {
                      return item
                    }
                    return null
                  })
                  if (exactFolder) {
                    request4.push(assignGroup(exactFolder?.id, userId))
                  }
                }
              }
            }
          }

          if (!!client) {
            // assigne student vào client
            request3.push(assigneStudent(userId, client))
            // asigne student vao toan bo course
            if (!!clientCourses) {
              clientCourses.forEach((couse) =>
                request2.push(
                  postEnroll(get(couse, 'channel_id'), 0, userId, partnerId)
                )
              )
            }
          } else {
            if (courseName)
              request2.push(postEnroll(courseId, scheduleId, userId, partnerId))
          }
        } else {
          const userAccount = await getUserByEmail(csvData[i][1].toLowerCase())

          // assigne student to group
          if (groupsValue && groups) {
            for (let i = 0; i < groups.length; i++) {
              const folder = groups[i]

              const folders = folder.split('>')
              if (folders.length === 1) {
                const child = folders[0]
                const childItem = foldersData?.find(
                  (item) =>
                    item.name.trim().toLowerCase() ===
                    child.trim().toLowerCase()
                )
                if (childItem) {
                  request4.push(assignGroup(childItem?.id, userAccount?.id))
                }
              } else {
                const clientName = folders[0]
                const childName = folders[1]
                const clientItem = foldersData.find(
                  (item) =>
                    item.name.trim().toLowerCase() ===
                    clientName.trim().toLowerCase()
                )
                const groupLevel2 = getGroupByLevel(
                  foldersData,
                  2,
                  clientItem?.id
                )

                const childItem = foldersData?.filter(
                  (item) =>
                    item?.level !== 1 &&
                    item.name.trim().toLowerCase() ===
                      childName.trim().toLowerCase()
                )

                if (childItem?.length === 1) {
                  request4.push(assignGroup(childItem[0]?.id, userAccount?.id))
                } else {
                  const exactFolder = childItem?.find((item) => {
                    const findParentFolder = groupLevel2?.find(
                      (item2) => item2?.id === item.parent_id
                    )

                    if (findParentFolder) {
                      return item
                    }
                    return null
                  })
                  if (exactFolder) {
                    request4.push(assignGroup(exactFolder?.id, userAccount?.id))
                  }
                }
              }
            }
          }

          if (userAccount) {
            request2.push(
              signUpUser(
                {
                  id: userAccount.id,
                  login: userAccount.login,
                  name,
                  password,
                  isAdmin,
                },
                dispatch
              )
            )
            const userId = get(userAccount, `id`)
            const partnerId = get(userAccount, `partner_id`)

            if (!!client) {
              request3.push(assigneStudent(userId, client))
              if (!!clientCourses) {
                clientCourses.forEach((couse) =>
                  request2.push(
                    postEnroll(get(couse, 'channel_id'), 0, userId, partnerId)
                  )
                )
              }
            } else {
              if (userId && partnerId && courseName) {
                request2.push(
                  postEnroll(courseId, scheduleId, userId, partnerId)
                )
              }
            }
          }
        }
      }
    }

    // handle assinge student to course
    const data2 = await Promise.all(request2)

    // handle assinge student to client
    const data3 = await Promise.all(request3)

    const data4 = await Promise.all(request4)

    const error =
      data.every((item) => !item) &&
      data2.every((item) => !item) &&
      data3.every((item) => !item) &&
      data4.every((item) => !item)

    console.log('error', error)

    if (!error) {
      showSuccess('Import Users Success!')
      dispatch(showProcessingModal({ status: 'completed' }))
      dispatch(loadListUser({ current: 1 }))
    } else {
      showError('Import Users Failed! Please try again.')
      dispatch(hideProcessingModal({ status: 'completed' }))
    }
  }
  const handleDownloadTemplate = () => {
    saveAs(fileUrl, 'import_user.csv')
  }

  return (
    <Modal visible={showImportUsers} footer={null} onCancel={handleCancel}>
      <div>
        <div
          className="ld-modal__warning"
          style={{ textAlign: 'left', marginBottom: 10 }}
        >
          Import Users{' '}
          <span
            style={{
              fontWeight: 'normal',
              fontSize: 13,
              textDecoration: 'underline',
              paddingLeft: 12,
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={handleDownloadTemplate}
          >
            Download Template
          </span>
        </div>
        <div style={{ marginBottom: 20 }}>
          <CSVReader
            onFileLoaded={(data) => {
              data.shift()
              setCsvData(data)
            }}
          />
        </div>
        <div>
          <BtnPrimary
            name="Import"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
            }}
            handleClick={handleImport}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ImportUsersModal
