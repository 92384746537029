import * as constants from './constants'

// POST COURSE
export function showGlobalLoading(payload) {
  return {
    type: constants.SHOW_GLOBAL_LOADING,
    payload,
  }
}

export function hideGlobalLoading(payload) {
  return {
    type: constants.HIDE_GLOBAL_LOADING,
    payload,
  }
}

export function showMarkingModal(payload, schedule, currentUser, remarks) {
  return {
    type: constants.SHOW_MARKING_MODAL,
    payload,
    schedule,
    currentUser,
    remarks,
  }
}

export function hideMarkingModal(payload) {
  return {
    type: constants.HIDE_MARKING_MODAL,
    payload,
  }
}

export function showMarkingOutComeModal(payload) {
  return {
    type: constants.SHOW_MARKING_OUTCOME_MODAL,
    payload,
  }
}

export function hideMarkingOutComeModal(payload) {
  return {
    type: constants.HIDE_MARKING_OUTCOME_MODAL,
    payload,
  }
}

export function showFormSchedule(payload, page) {
  return {
    type: constants.SHOW_FORM_SCHEDULE,
    payload,
    page,
  }
}

export function hideFormSchedule(payload) {
  return {
    type: constants.HIDE_FORM_SCHEDULE,
    payload,
  }
}

export function showDeleteModal(payload) {
  return {
    type: constants.SHOW_DELETE_MODAL,
    payload,
  }
}

export function hideDeleteModal(payload) {
  return {
    type: constants.HIDE_DELETE_MODAL,
    payload,
  }
}

export function showDeleteSchedule(payload, page) {
  return {
    type: constants.SHOW_DELETE_SCHEDULE,
    payload,
    page,
  }
}

export function hideDeleteSchedule(payload) {
  return {
    type: constants.HIDE_DELETE_SCHEDULE,
    payload,
  }
}

export function showDeleteLessonModal(payload, resetForm) {
  return {
    type: constants.SHOW_DELETE_LESSON_MODAL,
    payload,
    resetForm,
  }
}

export function hideDeleteLessonModal(payload) {
  return {
    type: constants.HIDE_DELETE_LESSON_MODAL,
    payload,
  }
}

export function showFormOutcomeModal(payload, resetForm) {
  return {
    type: constants.SHOW_FORM_OUTCOME_MODAL,
    payload,
  }
}

export function hideFormOutcomeModal(payload) {
  return {
    type: constants.HIDE_FORM_OUTCOME_MODAL,
    payload,
  }
}

export function showDeleteOutcomeModal(payload, resetForm) {
  return {
    type: constants.SHOW_DELETE_OUTCOME_MODAL,
    payload,
  }
}

export function hideDeleteOutcomeModal(payload) {
  return {
    type: constants.HIDE_DELETE_OUTCOME_MODAL,
    payload,
  }
}

export function showDetailsRegistration(payload) {
  return {
    type: constants.SHOW_DETAILS_REGISTRATION,
    payload,
  }
}

export function hideDetailsRegistration(payload) {
  return {
    type: constants.HIDE_DETAILS_REGISTRATION,
    payload,
  }
}

export function showFormUserModal(payload) {
  return {
    type: constants.SHOW_FORM_USER_MODAL,
    payload,
  }
}

export function hideFormUserModal(payload) {
  return {
    type: constants.HIDE_FORM_USER_MODAL,
    payload,
  }
}

export function showFormClientModal(params) {
  return {
    type: constants.SHOW_FORM_CLIENT_MODAL,
    params,
  }
}

export function hideFormClientModal(payload) {
  return {
    type: constants.HIDE_FORM_CLIENT_MODAL,
    payload,
  }
}

export function showDeleteEnrollmentModal(payload) {
  return {
    type: constants.SHOW_DELETE_ENROLLMENT_MODAL,
    payload,
  }
}

export function hideDeleteEnrollmentModal(payload) {
  return {
    type: constants.HIDE_DELETE_ENROLLMENT_MODAL,
    payload,
  }
}

export function showConfirmQuizModal(params) {
  return {
    type: constants.SHOW_CONFIRM_QUIZ_MODAL,
    params,
  }
}

export function hideConfirmQuizModal(payload) {
  return {
    type: constants.HIDE_CONFIRM_QUIZ_MODAL,
    payload,
  }
}

export function showReorderQuizModal(params) {
  return {
    type: constants.SHOW_REORDER_QUIZ_MODAL,
    params,
  }
}

export function hideReorderQuizModal(payload) {
  return {
    type: constants.HIDE_REORDER_QUIZ_MODAL,
    payload,
  }
}

export function showForgotPasswordModal(params) {
  return {
    type: constants.SHOW_FORGOT_PASSWORD_MODAL,
    params,
  }
}

export function hideForgotPasswordModal(payload) {
  return {
    type: constants.HIDE_FORGOT_PASSWORD_MODAL,
    payload,
  }
}

export function showExportRegistrations(params) {
  return {
    type: constants.SHOW_EXPORT_REGISTRATIONS,
    params,
  }
}

export function hideExportRegistrations(payload) {
  return {
    type: constants.HIDE_EXPORT_REGISTRATIONS,
    payload,
  }
}

export function showSelectPriceSFCModal(payload, submitRef, isComplete) {
  return {
    type: constants.SHOW_SELECT_PRICE_SFC_MODAL,
    payload,
    submitRef,
    isComplete,
  }
}

export function hideSelectPriceSFCModal(payload) {
  return {
    type: constants.HIDE_SELECT_PRICE_SFC_MODAL,
    payload,
  }
}

export function showSelectScheduleModule(payload) {
  return {
    type: constants.SHOW_SELECT_SCHEDULE_MODAL,
    payload,
  }
}

export function hideSelectScheduleModule(payload) {
  return {
    type: constants.HIDE_SELECT_SCHEDULE_MODAL,
    payload,
  }
}

export function showVideoPlayer(payload) {
  return {
    type: constants.SHOW_VIDEO_PLAYER,
    payload,
  }
}

export function hideVideoPlayer(payload) {
  return {
    type: constants.HIDE_VIDEO_PLAYER,
    payload,
  }
}

export function showDeleteLearningOutcome(payload, selectedSchedule, id) {
  return {
    type: constants.SHOW_DELETE_LEARNING_OUTCOME,
    payload,
    selectedSchedule,
    id,
  }
}

export function hideDeleteLearningOutcome(payload) {
  return {
    type: constants.HIDE_DELETE_LEARNING_OUTCOME,
    payload,
  }
}

export function showExportUsers(params) {
  return {
    type: constants.SHOW_EXPORT_USERS,
    params,
  }
}

export function hideExportUsers(params) {
  return {
    type: constants.HIDE_EXPORT_USERS,
    params,
  }
}

export function showExportCourseReport(payload) {
  return {
    type: constants.SHOW_EXPORT_COURSE_REPORT,
    payload,
  }
}

export function hideExportCourseReport(params) {
  return {
    type: constants.HIDE_EXPORT_COURSE_REPORT,
    params,
  }
}

export function showExportQuizReport(payload) {
  return {
    type: constants.SHOW_EXPORT_QUIZ_REPORT,
    payload,
  }
}

export function hideExportQuizReport(params) {
  return {
    type: constants.HIDE_EXPORT_QUIZ_REPORT,
    params,
  }
}

export function showExportQuizReportPDF(payload) {
  return {
    type: constants.SHOW_EXPORT_QUIZ_REPORT_PDF,
    payload,
  }
}

export function hideExportQuizReportPDF(params) {
  return {
    type: constants.HIDE_EXPORT_QUIZ_REPORT_PDF,
    params,
  }
}

export function showExportInvoices(params) {
  return {
    type: constants.SHOW_EXPORT_INVOICES,
    params,
  }
}

export function hideExportInvoices(payload) {
  return {
    type: constants.HIDE_EXPORT_INVOICES,
    payload,
  }
}

export function showImportUsers(params) {
  return {
    type: constants.SHOW_IMPORT_USERS,
    params,
  }
}

export function hideImportUsers(payload) {
  return {
    type: constants.HIDE_IMPORT_USERS,
    payload,
  }
}

export function showProcessingModal(params) {
  return {
    type: constants.SHOW_PROCESSING_MODAL,
    params,
  }
}

export function hideProcessingModal(payload) {
  return {
    type: constants.HIDE_PROCESSING_MODAL,
    payload,
  }
}

export function setCountUserImported(payload) {
  return {
    type: constants.SET_COUNT_USER_IMPORTED,
    payload,
  }
}

export function setCountUserAssignedToClient(payload) {
  return {
    type: constants.SET_COUNT_USER_ASSIGNED_TO_COURSE,
    payload,
  }
}

export function setCountUserAssignedToFolder(payload) {
  return {
    type: constants.SET_COUNT_USER_ASSIGNED_TO_FOLDER,
    payload,
  }
}

export function setCountUserEnrolled(payload) {
  return {
    type: constants.SET_COUNT_USER_ENROLLED,
    payload,
  }
}

export function showOnBoardingAdminModal(params) {
  return {
    type: constants.SHOW_ONBOARDING_ADMIN_MODAL,
    params,
  }
}

export function hideOnBoardingAdminModal(payload) {
  return {
    type: constants.HIDE_ONBOARDING_ADMIN_MODAL,
    payload,
  }
}

export function showPreviewModal(params) {
  return {
    type: constants.SHOW_PREVIEW_MODAL,
    params,
  }
}

export function hidePreviewModal(payload) {
  return {
    type: constants.HIDE_PREVIEW_MODAL,
    payload,
  }
}

export function showBellsCourseReport(payload) {
  return {
    type: constants.SHOW_BELLS_COURSE_REPORT,
    payload,
  }
}

export function hideBellsCourseReport(payload) {
  return {
    type: constants.HIDE_BELLS_COURSE_REPORT,
    payload,
  }
}
