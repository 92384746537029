import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as selectors from './selectors'
import MarkingModal from './components/MarkingModal'
import GlobalLoading from './components/GlobalLoading'
import { makeSelectCourse } from '../Home/selectors'
import FormScheduleModal from './components/FormScheduleModal'
import DeleteCourseModal from './components/DeleteCourseModal'
import DeleteScheduleModal from './components/DeleteScheduleModal'
import DeleteLessonModal from './components/DeleteLessonModal'
import FormOutcomeModal from './components/FormOutcomeModal'
import DeleteOutcomeModal from './components/DeleteOutcomeModal'
import ViewDetailsRegistration from './components/ViewDetailsRegistration'
import MarkingOutComeModal from './components/MarkingOutComeModal'
import FormUserModal from './components/SuperAdminModal/FormUserModal'
import FormClientModal from './components/SuperAdminModal/FormClientModal'
import DeleteEnrollmentModal from './components/DeleteEnrollmentModal'
import ConfirmQuizModal from './components/ConfirmQuizModal'
import ReorderQuizModal from './components/ReorderQuizModal'
import ForgotPasswordModal from './components/ForgotPasswordModal'
import ExportRegistrations from './components/ExportRegistrations'
import SelectPriceSFCModal from './components/SelectPriceSFCModal'
import SelectScheduleModal from './components/SelectScheduleModal'
import VideoPlayerModal from './components/VideoPlayerModal'
import DeleteLearningOutCome from './components/DeleteLearningOutCome'
import ExportUsers from './components/ExportUserDetails'
import ExportCourseReport from './components/ExportCourseReport'
import ExportQuizReport from './components/ExportQuizReport'
import ExportInvoicesModal from './components/ExportInvoicesModal'
import ImportUsersModal from './components/ImportUsersModal'
import ProcessingModal from './components/ProcessingModal'
import OnBoardingAdminModal from './components/OnBoardingAdminModal'
import PreviewModal from './components/PreviewModal'
import BellsCourseReport from './components/BellsCourseReport'
import ExportQuizReportPDF from './components/ExportQuizReportPDF'

const Modal = () => {
  const dispatch = useDispatch()
  const showGlobalLoading = useSelector(selectors.makeSelectShowGlobalLoading())
  const userAnswer = useSelector(selectors.makeSelectUserAnswer())
  const showFormSchedule = useSelector(selectors.makeSelectShowFormSchedule())
  const showMarkingModal = useSelector(selectors.makeSelectShowMarkingModal())
  const selectedSchedule = useSelector(selectors.makeSelectSelectedSchedule())
  const course = useSelector(makeSelectCourse())
  const schedule = useSelector(selectors.makeSelectSchedule())

  const showDeleteModal = useSelector(selectors.makeSelectShowDeleteModal())

  const showDeleteScheduleModal = useSelector(
    selectors.makeSelectShowDeleteScheduleModal()
  )

  const showDeleteLessonModal = useSelector(
    selectors.makeSelectShowDeleteLessonModal()
  )
  const slide = useSelector(selectors.makeSelectSlide())
  const resetForm = useSelector(selectors.makeSelectResetForm())
  const showFormOutcomeModal = useSelector(
    selectors.makeSelectShowFormOutcome()
  )

  const showDeleteOutcomeModal = useSelector(
    selectors.makeSelectShowDeleteOutcomeModal()
  )

  const outCome = useSelector(selectors.makeSelectOutcome())
  const showDetailsRegistration = useSelector(
    selectors.makeSelectDetailsRegistration()
  )
  const partner = useSelector(selectors.makeSelectPartner())

  // marking outcome modal
  const showMarkingOutComeModal = useSelector(
    selectors.makeSelectMarkingOutComeModal()
  )
  const dataMarkingOutcome = useSelector(
    selectors.makeSelectDataMarkingOutCome()
  )
  const currentUser = useSelector(selectors.makeSelectDataCurrentUser())

  const showFormUserModal = useSelector(selectors.makeSelectShowFormUserModal())

  const user = useSelector(selectors.makeSelectUser())

  const showDeleteEnrollmentModal = useSelector(
    selectors.makeSelectShowDeleteEnrollmentModal()
  )

  const enrollment = useSelector(selectors.makeSelectEnrollment())

  const showConfirmQuizModal = useSelector(
    selectors.makeSelectShowConfirmQuizModal()
  )

  const showReorderQuizModal = useSelector(
    selectors.makeSelectShowReorderQuizModal()
  )

  const answerQuizId = useSelector(selectors.makeSelectAnswerQuizId())

  const isLastAnswer = useSelector(selectors.makeSelectIsLastAnswer())
  const userLogByCourse = useSelector(selectors.makeSelectUserLogByCourse())

  const showForgotPasswordModal = useSelector(
    selectors.makeSelectShowForgotPasswordModal()
  )

  const showExportRegistrations = useSelector(
    selectors.makeSelectShowExportRegistrations()
  )

  const showSelectPriceSFCModal = useSelector(
    selectors.makeSelectSelectPriceSFCModal()
  )
  const optionsPrice = useSelector(selectors.makeSelectOptionsPrice())

  const submitRef = useSelector(selectors.makeSelectSubmitRef())

  const selectSchedule = useSelector(selectors.makeSelectScheduleModal())

  const page = useSelector(selectors.makeSelectPage())

  const isComplete = useSelector(selectors.makeSelectIsComplete())

  const showVideoPlayer = useSelector(selectors.makeSelectShowVideoPlayer())

  const showDeleteLearningOutcome = useSelector(
    selectors.makeSelectShowDeleteLearningOutcome()
  )
  const courseId = useSelector(selectors.makeSelectCourseId())

  const showExportUsersModal = useSelector(
    selectors.makeSelectShowExportUsers()
  )

  const showExportCourseReport = useSelector(
    selectors.makeSelectShowExportCourseReport()
  )

  const showExportQuizReport = useSelector(
    selectors.makeSelectShowExportQuizReport()
  )

  const showExportQuizReportPDF = useSelector(
    selectors.makeSelectShowExportQuizReportPDF()
  )

  const courseSelected = useSelector(selectors.makeSelectCourseSelected())

  const showExportInvoices = useSelector(
    selectors.makeSelectShowExportInvoices()
  )

  const showImportUsers = useSelector(selectors.makeSelectShowImportUsers())

  const showProcessingModal = useSelector(
    selectors.makeSelectShowProcessingModal()
  )

  const countUserImported = useSelector(selectors.makeSelectCountUserImported())

  const countUserEnrolled = useSelector(selectors.makeSelectCountUserEnrolled())

  const countUserAssigned = useSelector(selectors.makeSelectCountUserAssigned())

  const countUserAssignedFolder = useSelector(
    selectors.makeSelectCountUserAssignedFolder()
  )

  const processingModalPayload = useSelector(
    selectors.makeSelectProcessingModalPayload()
  )

  const showOnboardingAdminModal = useSelector(
    selectors.makeSelectShowOnboardingAdminModal()
  )

  const showPreviewModal = useSelector(selectors.makeSelectShowPreviewModal())

  const showFormClientModal = useSelector(
    selectors.makeSelectShowFormClientModal()
  )

  const showBellsCourseReport = useSelector(
    selectors.makeSelectShowBellsCourseReport()
  )
  const clientsData = useSelector(selectors.makeSelectClientsData())

  const remarks = useSelector(selectors.makeSelectUserRemarks())
  return (
    <div>
      {showGlobalLoading && <GlobalLoading />}
      <MarkingModal
        dispatch={dispatch}
        userAnswer={userAnswer}
        remarks={remarks}
        showMarkingModal={showMarkingModal}
        selectedSchedule={selectedSchedule}
      />

      {showFormSchedule && (
        <FormScheduleModal
          showFormSchedule={showFormSchedule}
          dispatch={dispatch}
          schedule={schedule}
          page={page}
        />
      )}
      <DeleteCourseModal
        course={course}
        dispatch={dispatch}
        showDeleteModal={showDeleteModal}
      />
      <DeleteScheduleModal
        showDeleteScheduleModal={showDeleteScheduleModal}
        page={page}
        dispatch={dispatch}
        schedule={schedule}
      />

      <DeleteLessonModal
        showDeleteLessonModal={showDeleteLessonModal}
        dispatch={dispatch}
        slide={slide}
        resetForm={resetForm}
      />
      {showFormOutcomeModal && (
        <FormOutcomeModal
          showFormOutcomeModal={showFormOutcomeModal}
          dispatch={dispatch}
          outCome={outCome}
        />
      )}
      {showDeleteOutcomeModal && (
        <DeleteOutcomeModal
          dispatch={dispatch}
          showDeleteOutcomeModal={showDeleteOutcomeModal}
          outCome={outCome}
        />
      )}

      {showDetailsRegistration && (
        <ViewDetailsRegistration
          dispatch={dispatch}
          showDetailsRegistration={showDetailsRegistration}
          partner={partner}
        />
      )}

      {showMarkingOutComeModal && (
        <MarkingOutComeModal
          dispatch={dispatch}
          showMarkingOutComeModal={showMarkingOutComeModal}
          dataMarkingOutcome={dataMarkingOutcome}
        />
      )}

      {showFormUserModal && (
        <FormUserModal
          showFormUserModal={showFormUserModal}
          dispatch={dispatch}
          user={user}
        />
      )}

      {showFormClientModal?.open && (
        <FormClientModal
          showFormClientModal={showFormClientModal}
          dispatch={dispatch}
        />
      )}
      {showDeleteEnrollmentModal && (
        <DeleteEnrollmentModal
          dispatch={dispatch}
          enrollment={enrollment}
          showDeleteEnrollmentModal={showDeleteEnrollmentModal}
        />
      )}

      {showConfirmQuizModal && (
        <ConfirmQuizModal
          dispatch={dispatch}
          answerQuizId={answerQuizId}
          showConfirmQuizModal={showConfirmQuizModal}
          isLastAnswer={isLastAnswer}
          userLogByCourse={userLogByCourse}
        />
      )}

      {showReorderQuizModal && (
        <ReorderQuizModal
          dispatch={dispatch}
          showReorderQuizModal={showReorderQuizModal}
          useSelector={useSelector}
        />
      )}

      {showForgotPasswordModal && (
        <ForgotPasswordModal
          dispatch={dispatch}
          showForgotPasswordModal={showForgotPasswordModal}
        />
      )}

      {showExportRegistrations && (
        <ExportRegistrations
          dispatch={dispatch}
          showExportRegistrations={showExportRegistrations}
        />
      )}

      {showSelectPriceSFCModal && (
        <SelectPriceSFCModal
          dispatch={dispatch}
          price={optionsPrice}
          submitRef={submitRef}
          isComplete={isComplete}
          showSelectPriceSFCModal={showSelectPriceSFCModal}
        />
      )}

      {selectSchedule && (
        <SelectScheduleModal
          dispatch={dispatch}
          selectSchedule={selectSchedule}
        />
      )}

      {showVideoPlayer && (
        <VideoPlayerModal
          dispatch={dispatch}
          showVideoPlayer={showVideoPlayer}
        />
      )}

      {showDeleteLearningOutcome && (
        <DeleteLearningOutCome
          dispatch={dispatch}
          showDeleteLearningOutcome={showDeleteLearningOutcome}
          outCome={outCome}
          selectedSchedule={selectedSchedule}
          courseId={courseId}
        />
      )}
      {showExportUsersModal && (
        <ExportUsers
          showExportUsersModal={showExportUsersModal}
          dispatch={dispatch}
          clientsData={clientsData}
        />
      )}
      {showExportCourseReport && (
        <ExportCourseReport
          dispatch={dispatch}
          courseSelected={courseSelected}
          showExportCourseReport={showExportCourseReport}
        />
      )}

      {showExportQuizReport && (
        <ExportQuizReport
          dispatch={dispatch}
          courseSelected={courseSelected}
          showExportQuizReport={showExportQuizReport}
        />
      )}

      {showExportQuizReportPDF && (
        <ExportQuizReportPDF
          dispatch={dispatch}
          courseSelected={courseSelected}
          showExportQuizReportPDF={showExportQuizReportPDF}
        />
      )}

      {showBellsCourseReport && (
        <BellsCourseReport
          dispatch={dispatch}
          courseSelected={courseSelected}
          showBellsCourseReport={showBellsCourseReport}
        />
      )}

      {showExportInvoices && (
        <ExportInvoicesModal
          dispatch={dispatch}
          showExportInvoices={showExportInvoices}
        />
      )}

      {showImportUsers && (
        <ImportUsersModal
          dispatch={dispatch}
          showImportUsers={showImportUsers}
        />
      )}

      {showProcessingModal && (
        <ProcessingModal
          dispatch={dispatch}
          countUserImported={countUserImported}
          countUserEnrolled={countUserEnrolled}
          countUserAssigned={countUserAssigned}
          countUserAssignedFolder={countUserAssignedFolder}
          processingModalPayload={processingModalPayload}
          showProcessingModal={showProcessingModal}
        />
      )}

      {showOnboardingAdminModal && (
        <OnBoardingAdminModal
          dispatch={dispatch}
          showOnboardingAdminModal={showOnboardingAdminModal}
        />
      )}

      {showPreviewModal && (
        <PreviewModal dispatch={dispatch} showPreviewModal={showPreviewModal} />
      )}
    </div>
  )
}
export default Modal
