import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportQuizReport,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { useSelector } from 'react-redux'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  encodedSlideByQuiz,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { loadSlides } from 'container/Home/actions'
import { makeSelectSlides } from 'container/Home/selectors'
import { getUserInfo } from 'utils/request'
import useLoadCourses from '../../../../hooks/useLoadCourses'
import { parseSafe } from '../../../../utils/helper'
import { alphabet } from '../../../../component/ChoiceItem'
import XLSX from 'sheetjs-style'
import { makeSelectDomain } from '../../../Home/selectors'
import { DOMAIN } from '../../../../routes'

const ExportQuizReport = ({
  dispatch,
  courseSelected,
  showExportQuizReport,
}) => {
  const domain = useSelector(makeSelectDomain())
  const isWFADomain = get(domain, 'domain', '') === DOMAIN.WFA
  const slides = useSelector(makeSelectSlides())
  const slidesRemoveSection = useMemo(() => {
    const arr = []
    Array.isArray(slides) &&
      slides.forEach((slide) => {
        arr.push(...slide.items?.filter((item) => item.questions?.length))
      })
    return arr
  }, [slides])
  const courses = useLoadCourses()
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)

  const scheduleData = useMemo(
    () => schedules?.find((item) => item?.id === selectedSchedule?.value),
    [selectedSchedule]
  )

  const course = useMemo(() => {
    if (selectedCourse?.value && courses) {
      return courses.find((course) => course.id === selectedCourse.value)
    }
  }, [selectedCourse?.value, courses])

  const requireSchedule = get(course, 'is_schedule', false)
  const channelID = get(selectedCourse, 'value', '')
  const scheduleLabel = get(selectedSchedule, 'label', '')
  const scheduleID = get(selectedSchedule, 'value', 0)
  useEffect(() => {
    if (courseSelected) {
      setSelectedCourse({
        value: courseSelected.id,
        label: courseSelected.name,
      })
    }
  }, [courseSelected])

  useEffect(() => {
    if (channelID) {
      dispatch(loadSlides(channelID, false, 0))
    }
  }, [channelID])

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getSchedules(selectedCourse.value, true, null, false, true))
    }
  }, [selectedCourse])

  const handleCancel = () => dispatch(hideExportQuizReport())

  const defaultAttachment = (length) => {
    const objectAttachment = {}
    for (let i = 0; i < length; i++) {
      objectAttachment[`Attachment ${++i}`] = ''
    }
    return objectAttachment
  }

  const handleExport = async (isExportAll = false) => {
    if (selectedCourse) {
      let requestURL = `${config.baseUrl}/api/lms/courses/channels/${
        selectedCourse.value
      }/partners?schedule_id=${
        !isExportAll ? get(selectedSchedule, 'value', 0) : 0
      }&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        const dataCSV = []

        let totalAttachment = 0
        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserQuizPassRate = []
          for (let i = 0; i < data.data.length; i++) {
            const userId = get(data.data, `[${i}].user.id`)
            requests.push(getUserInfo(userId, course?.website_id))
            requestsUserQuizPassRate.push(fetchAllAnswerByUser(userId))
          }

          const usersInfo = await Promise.all(requests)
          const userQuizPassRate = await Promise.all(requestsUserQuizPassRate)

          for (let i = 0; i < data.data.length; i++) {
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].user.partner.name`, '')
            const nric = get(usersInfo, `[${i}].passport`, '')

            const userID = get(data, `data[${i}].user.id`, '')
            let content = []

            const allUserAnswer = userQuizPassRate?.find((item) => {
              const key = Object.keys(item)
              if (userID === +key[0]) {
                return item
              }
            })

            for (let j = 0; j < slidesRemoveSection.length; j++) {
              if (slidesRemoveSection[j]?.questions.length > 0) {
                for (
                  let h = 0;
                  h < slidesRemoveSection[j]?.questions.length;
                  h++
                ) {
                  const lessonName = slidesRemoveSection[j]?.name
                  const question = slidesRemoveSection[j]?.questions[h]

                  const userAnswer = allUserAnswer
                    ? allUserAnswer[userID].find((item) => {
                        const isMatch =
                          item.slide_id === question?.slide_id &&
                          item?.question_id === question?.id
                        return isMatch
                      })
                    : null

                  var htmlQuestion = question?.question
                  var questionText = document.createElement('div')
                  questionText.innerHTML = htmlQuestion

                  var answer =
                    userAnswer && userAnswer?.value.replace(/-/g, '_')
                  var answerText = document.createElement('textarea')
                  const attachment = parseSafe(
                    get(userAnswer, 'file_attachments', '')
                  )

                  const isExistAttachment =
                    Array.isArray(attachment) && attachment?.length > 0
                  totalAttachment =
                    attachment?.length && isExistAttachment
                      ? attachment?.length
                      : totalAttachment
                  const hyperLinkAnswer = userAnswer?.url
                    ? {
                        t: 's',
                        v: userAnswer?.url,
                        l: {
                          Target: 'https://' + userAnswer?.url,
                        },
                        s: {
                          font: {
                            color: {
                              rgb: '0161FF',
                            },
                          },
                        },
                      }
                    : {}

                  const attachmentObject = isExistAttachment
                    ? attachment &&
                      attachment?.reduce((acc, curr, index) => {
                        let tempIndex =
                          Object.keys(hyperLinkAnswer).length > 0
                            ? index + 1
                            : index

                        return {
                          ...acc,
                          ...(Object.keys(hyperLinkAnswer).length > 0 && {
                            [`Attachment 1`]: hyperLinkAnswer,
                          }),
                          [`Attachment ${++tempIndex}`]: {
                            t: 's',
                            v: curr?.name,
                            l: {
                              Target: curr?.url,
                            },
                            s: {
                              font: {
                                color: {
                                  rgb: '0161FF',
                                },
                              },
                            },
                          },
                        }
                      }, {})
                    : Object.keys(hyperLinkAnswer).length > 0
                    ? {
                        [`Attachment 1`]: hyperLinkAnswer,
                      }
                    : defaultAttachment(totalAttachment)

                  answerText.innerHTML = answer

                  const multipleChoices = parseSafe(
                    get(question, 'multiple_choices', '')
                  )

                  if (multipleChoices) {
                    const parseAnswer = parseSafe(userAnswer?.value)
                    const answer = parseAnswer
                      ?.map(
                        (item) =>
                          `${alphabet[item.order]}. ${get(
                            multipleChoices?.find(
                              (x) => x.order === item.order
                            ),
                            'text',
                            ''
                          )}`
                      )
                      ?.join(', ')

                    content = {
                      Schedule: scheduleLabel,
                      'Full Name': name,
                      NRIC: nric,
                      'Lesson Name': lessonName,
                      'Assessment Date': dayjs(userAnswer?.create_date).format(
                        'DD/MM/YYYY LTS'
                      ),
                      Question: questionText.innerText,
                      Answer: answer,
                      Marks: userAnswer?.competent
                        ? 'C'
                        : {
                            t: 's',
                            v: 'NC',
                            s: {
                              font: {
                                color: {
                                  rgb: 'FF0000',
                                },
                              },
                            },
                          },
                      Remarks: userAnswer?.remark,
                    }
                  } else {
                    content = {
                      Schedule: scheduleLabel,
                      'Full Name': name,
                      NRIC: nric,
                      'Lesson Name': lessonName,
                      'Assessment Date': dayjs(userAnswer?.create_date).format(
                        'DD/MM/YYYY LTS'
                      ),
                      Question: questionText.innerText,
                      Answer: (answer && answerText.innerText) || '',
                      ...attachmentObject,
                      Marks: userAnswer?.competent
                        ? 'C'
                        : {
                            t: 's',
                            v: 'NC',
                            s: {
                              font: {
                                color: {
                                  rgb: 'FF0000',
                                },
                              },
                            },
                          },
                      Remarks: userAnswer?.remark,
                    }
                  }

                  dataCSV.push(content)
                }
              }
            }
          }
          if (isWFADomain) {
            const ws = XLSX.utils.json_to_sheet(dataCSV)
            const csvData = XLSX.utils.sheet_to_csv(ws)

            const blob = new Blob([csvData], {
              type: 'text/csv;charset=utf-8;',
            })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.setAttribute('download', `${course?.name}_Quiz_Report.csv`)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          } else {
            const ws = XLSX.utils.json_to_sheet(
              restructureObjectForSheet(dataCSV)
            )
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
            XLSX.writeFile(
              wb,
              `${course?.name}_Quiz Report${
                isExportAll
                  ? ' (All)'
                  : requireSchedule && scheduleData
                  ? ` (${scheduleData?.class_code || ''})`
                  : ''
              }.xlsx`
            )
          }
        }

        handleCancel()
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  function restructureObjectForSheet(obj) {
    // for each object in the array put the keys in a new array
    // flatten that array
    // there will be duplicate names which can be removed with Set
    // turn it back into an array
    const uniqKeys = Array.from(new Set(obj.map((o) => Object.keys(o)).flat()))

    // remove LastLogin from this array
    // then put LastLogin at the end of the array
    const endKey = ['Marks', 'Remarks']
    const rowHeaders = uniqKeys
      .filter((k) => !endKey.includes(k))
      .concat(endKey)

    // process the original data into a new array
    // first entry will define row headers in Excel sheet
    const newData = obj.map((o) => {
      return rowHeaders.reduce((a, c) => {
        a[c] = o[c]
        return a
      }, {})
    })

    return newData
  }

  const fetchAllAnswerByUser = async (userId) => {
    const encodedSlideId = encodedSlideByQuiz(slidesRemoveSection)
    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/slides/answers?schedule_id=${scheduleID}${encodedSlideId}&order_by=id%20asc&per_page=1000&ignore_per_page=true`,
        {
          method: 'GET',
        }
      )
      const data = await res.json()

      return { [userId]: data.data }
    } catch (error) {
      console.log('err', error)
    }
  }

  return (
    <Modal visible={showExportQuizReport} footer={null} onCancel={handleCancel}>
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Quiz Report
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={() => handleExport(true)}
          />
        </div>
        <div className="sc-modal__label">Courses</div>
        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(_, course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {(selectedCourse && selectedSchedule) || !requireSchedule ? (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={() => handleExport(false)}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export default ExportQuizReport
