import { createSelector } from 'reselect'
import { showSelectPriceSFCModal } from './actions'

/**
 * Direct selector to the authorize state domain
 */

const selectHomeDomain = (state) => state.modal

export const makeSelectShowGlobalLoading = () =>
  createSelector(selectHomeDomain, (subState) => subState.showGlobalLoading)

export const makeSelectShowMarkingModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showMarkingModal)

export const makeSelectUserAnswer = () =>
  createSelector(selectHomeDomain, (subState) => subState.userAnswer)

export const makeSelectShowFormSchedule = () =>
  createSelector(selectHomeDomain, (subState) => subState.showFormSchedule)

export const makeSelectSchedule = () =>
  createSelector(selectHomeDomain, (subState) => subState.schedule)

export const makeSelectShowDeleteModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showDeleteModal)

export const makeSelectShowDeleteScheduleModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showDeleteScheduleModal
  )

export const makeSelectSlide = () =>
  createSelector(selectHomeDomain, (subState) => subState.slide)

export const makeSelectShowDeleteLessonModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showDeleteLessonModal)

export const makeSelectResetForm = () =>
  createSelector(selectHomeDomain, (subState) => subState.resetForm)

export const makeSelectShowFormOutcome = () =>
  createSelector(selectHomeDomain, (subState) => subState.showFormOutcomeModal)

export const makeSelectShowDeleteOutcomeModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showDeleteOutcomeModal
  )

export const makeSelectOutcome = () =>
  createSelector(selectHomeDomain, (subState) => subState.outcome)

export const makeSelectSelectedSchedule = () =>
  createSelector(selectHomeDomain, (subState) => subState.selectedSchedule)

export const makeSelectDetailsRegistration = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showDetailsRegistration
  )

export const makeSelectPartner = () =>
  createSelector(selectHomeDomain, (subState) => subState.partner)

export const makeSelectMarkingOutComeModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showMarkingOutComeModal
  )

export const makeSelectDataMarkingOutCome = () =>
  createSelector(selectHomeDomain, (subState) => subState.dataMarkingOutcome)

export const makeSelectDataCurrentUser = () =>
  createSelector(selectHomeDomain, (subState) => subState.currentUser)

export const makeSelectShowFormUserModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showFormUserModal)

export const makeSelectUser = () =>
  createSelector(selectHomeDomain, (subState) => subState.user)

export const makeSelectShowDeleteEnrollmentModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showDeleteEnrollmentModal
  )

export const makeSelectEnrollment = () =>
  createSelector(selectHomeDomain, (subState) => subState.enrollment)

export const makeSelectShowConfirmQuizModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showConfirmQuizModal)

export const makeSelectAnswerQuizId = () =>
  createSelector(selectHomeDomain, (subState) => subState.answerQuizId)

export const makeSelectIsLastAnswer = () =>
  createSelector(selectHomeDomain, (subState) => subState.isLastAnswer)

export const makeSelectUserLogByCourse = () =>
  createSelector(selectHomeDomain, (subState) => subState.userLogByCourse)

export const makeSelectShowReorderQuizModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showReorderQuizModal)

export const makeSelectShowForgotPasswordModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showForgotPasswordModal
  )

export const makeSelectShowExportRegistrations = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showExportRegistrations
  )

export const makeSelectSelectPriceSFCModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showSelectPriceSFCModal
  )

export const makeSelectOptionsPrice = () =>
  createSelector(selectHomeDomain, (subState) => subState.optionsPrice)

export const makeSelectSubmitRef = () =>
  createSelector(selectHomeDomain, (subState) => subState.submitRef)

export const makeSelectScheduleModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showSelectSchedule)

export const makeSelectPage = () =>
  createSelector(selectHomeDomain, (subState) => subState.page)

export const makeSelectIsComplete = () =>
  createSelector(selectHomeDomain, (subState) => subState.isComplete)

export const makeSelectShowVideoPlayer = () =>
  createSelector(selectHomeDomain, (subState) => subState.showVideoPlayer)

export const makeSelectShowDeleteLearningOutcome = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showDeleteLearningOutcome
  )

export const makeSelectCourseId = () =>
  createSelector(selectHomeDomain, (subState) => subState.courseId)

export const makeSelectShowExportUsers = () =>
  createSelector(selectHomeDomain, (subState) => subState.showExportUserModal)

export const makeSelectShowExportCourseReport = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showExportCourseReport
  )

export const makeSelectShowExportQuizReport = () =>
  createSelector(selectHomeDomain, (subState) => subState.showExportQuizReport)

export const makeSelectShowExportQuizReportPDF = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showExportQuizReportPDF
  )

export const makeSelectCourseSelected = () =>
  createSelector(selectHomeDomain, (subState) => subState.courseSelected)

export const makeSelectShowExportInvoices = () =>
  createSelector(selectHomeDomain, (subState) => subState.showExportInvoices)

export const makeSelectShowImportUsers = () =>
  createSelector(selectHomeDomain, (subState) => subState.showImportUsers)

export const makeSelectShowProcessingModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showProcessingModal)

export const makeSelectCountUserImported = () =>
  createSelector(selectHomeDomain, (subState) => subState.countUserImported)

export const makeSelectCountUserEnrolled = () =>
  createSelector(selectHomeDomain, (subState) => subState.countUserEnrolled)

export const makeSelectCountUserAssigned = () =>
  createSelector(selectHomeDomain, (subState) => subState.countUserAssigned)

export const makeSelectCountUserAssignedFolder = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.countUserAssignedFolder
  )

export const makeSelectProcessingModalPayload = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.processingModalPayload
  )

export const makeSelectShowOnboardingAdminModal = () =>
  createSelector(
    selectHomeDomain,
    (subState) => subState.showOnboardingAdminModal
  )

export const makeSelectShowPreviewModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showPreviewModal)

export const makeSelectShowFormClientModal = () =>
  createSelector(selectHomeDomain, (subState) => subState.showFormClientModal)

export const makeSelectShowBellsCourseReport = () =>
  createSelector(selectHomeDomain, (subState) => subState.showBellsCourseReport)

export const makeSelectClientsData = () =>
  createSelector(selectHomeDomain, (subState) => subState.clients)

export const makeSelectUserRemarks = () =>
  createSelector(selectHomeDomain, (subState) => subState.remarks)
