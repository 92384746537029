import React, { useState, useRef, useEffect, useMemo } from 'react'
import images from 'Themes/Images'
import { get, isBoolean, orderBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import parseHtml, { domToReact } from 'html-react-parser'
import { uploadImage, uploadPdf } from 'src/container/CMS/hooks.js'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CKEditorWrapper } from 'container/CMS/screens/Article'
import {
  setSelectedQuiz,
  loadUserAnswer,
  postUserAnswer,
  putAnswerTime,
  loadCourse,
  preventLoadRelatedApi,
} from '../../container/Home/actions'
import {
  makeSelectSelectedQuiz,
  makeSelectUserAnswer,
  makeSelectAnswerTime,
  makeSelectDomain,
} from '../../container/Home/selectors'
import './styles.scss'
import {
  SUPPORTED_ALL_FORMATS,
  SUPPORTED_FORMATS,
  SUPPORTED_FORMATS_DOCUMENT,
} from '../../utils/constants'
import {
  isEnrollCourse,
  getTypesSubmit,
  getAnswerTimeBySlide,
  getIsCompleteQuiz,
  parseSafe,
} from '../../utils/helper'
import { showError } from '../../utils/notification'
import config from '../../global-config'
import BtnPrimary from '../BtnPrimary'
import {
  hideGlobalLoading,
  showConfirmQuizModal,
  showGlobalLoading,
} from '../../container/Modal/actions'
import ListChoiceStudent from '../ListChoiceStudent/index'
import FinalResult from '../FinalResult/index'
import classNames from 'classnames'
import PreviewFilesUploadQuiz from '../PreviewFilesUploadQuiz'
import TextArea from 'antd/lib/input/TextArea'
import { Alert, Button, Space } from 'antd'
import { DOMAIN } from 'src/routes'
import { useClickOutside } from '../../utils/hooks'
import CompletePage from '../CompletePage'
import { makeSelectCurrentUser } from '../../container/Auth/selectos'
import FailPage from '../FailPage'
import Countdown from 'antd/lib/statistic/Countdown'
import confirm from 'antd/lib/modal/confirm'
import * as actions from 'container/Home/actions'
const TYPE_ANSWER = {
  ATTACHMENT: 'attachment',
  TEXT: 'text',
  URL: 'url',
}

const QuizLesson = ({
  slideDetails,
  course,
  handlePreviousSlide,
  handleNextSlide,
  id,
  userLogByCourse,
  slides,
}) => {
  const [visibleQuiz, setVisibleQuiz] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const dispatch = useDispatch()
  const answerTime = useSelector(makeSelectAnswerTime())
  const allAnswer = useSelector(makeSelectUserAnswer())
  const uniqueAnswers = useMemo(
    () => (Array.isArray(allAnswer) && uniqBy(allAnswer, 'question_id')) || [],
    [allAnswer]
  )
  const selectedQuiz = useSelector(makeSelectSelectedQuiz())
  const partners = get(course, 'partners', [])
  const questions = get(slideDetails, 'questions', [])

  const currentUser = useSelector(makeSelectCurrentUser())

  const isLastAnswer = answerTime?.filter((i) => !i?.end_date)?.length === 1

  const isLastQuestion = useMemo(() => {
    return (
      selectedQuiz &&
      Array.isArray(questions) &&
      selectedQuiz.id === questions[questions?.length - 1]?.id
    )
  }, [questions, selectedQuiz])

  const isShowTimer = useMemo(
    () => slideDetails?.timer !== null,
    [slideDetails]
  )

  const multipleChoices = parseSafe(get(selectedQuiz, 'multiple_choices', ''))
  const correctAnswers =
    Array.isArray(multipleChoices) &&
    multipleChoices.filter((item) => item.is_correct)

  const domain = useSelector(makeSelectDomain())
  const correctAnswersLength = correctAnswers && correctAnswers.length
  const [text, setText] = useState('')
  const [chooseOptions, setChooseOptions] = useState([])
  const [chooseCorrectOptions, setChooseCorrectOptions] = useState(null)

  const isCorrectAnswer = slideDetails?.is_correct_answer
  const disableShowAnswer = slideDetails?.is_show_answer

  const [error, setError] = useState(null)
  const [videoUrl, setVideoUrl] = useState('')
  const [activeImage, setActiveImage] = useState({})
  const [files, setFiles] = useState([])
  const [isActiveTextbox, setIsActiveTextbox] = useState(false)
  const isBellDomain = get(domain, 'domain', '') === DOMAIN.AGB_EDUCATION
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA
  const isEnroll = isEnrollCourse(partners, course)
  const hiddenFileDoc = useRef(null)
  const [percentPass, setPercentPass] = useState(0)

  const timeoutRef = useRef(null)
  const isLogging = useRef(false)

  const answerTimeOfQuiz = getAnswerTimeBySlide(slideDetails, answerTime)

  const fetchAllAnswerByUser = async (userId) => {
    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/slides/answers?order_by=id%20asc&per_page=1000&ignore_per_page=true`,
        {
          method: 'GET',
        }
      )
      const data = await res.json()

      return data.data
    } catch (error) {
      console.log('err', error)
    }
  }

  const check = async () => {
    if (+localStorage.getItem('percentComplete') === 100) {
      const allAnswers = await fetchAllAnswerByUser(currentUser?.uid)
      const uniqAllAnswers =
        Array.isArray(allAnswers) && uniqBy(allAnswers, 'question_id')

      let temp = []
      for (let v = 0; v < slides.length; v++) {
        for (let i = 0; i < slides[v]?.items.length; i++) {
          const questionsByQuiz = slides[v]?.items[i]?.questions
          const allAnswersByQuiz = uniqAllAnswers?.filter((answer) =>
            questionsByQuiz?.find((x) => answer?.question_id === x?.id)
          )
          const totalCorrectAnswers =
            (allAnswersByQuiz &&
              allAnswersByQuiz?.filter((ite) => ite?.competent && ite?.value)
                .length) ||
            0

          const questionsLength =
            (questionsByQuiz &&
              questionsByQuiz?.filter((q) => !q?.types)?.length) ||
            0
          const passGrade = slides[v]?.items[i]?.quiz_pass_rate
          if (passGrade > 0) {
            temp.push(
              (totalCorrectAnswers / questionsLength) * 100 >= passGrade
            )
          }
        }
      }
      if (temp?.length > 0) {
        if (temp?.some((i) => !i)) {
          return
        } else {
          await handleSendEmail()
        }
      } else {
        await handleSendEmail()
      }
    }
  }

  const recursiveCall = (count) => {
    if (count >= 10) {
      return
    }

    if (+localStorage.getItem('percentComplete') === 100) {
      check()
      return (count = 10)
    } else {
      setTimeout(() => {
        recursiveCall(count + 1)
      }, 800)
    }
  }

  useEffect(() => {
    if (wfaDomain) {
      if (isComplete && !visibleQuiz) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
          if (!isLogging.current) {
            const savedNotPassQuiz =
              JSON.parse(localStorage.getItem('notPassedQuiz')) || []
            const savedCurrentSlide = JSON.parse(
              localStorage.getItem('currentSlideId')
            )
            const check = savedNotPassQuiz?.find(
              (i) => i?.id === savedCurrentSlide?.id
            )
            if (
              slideDetails?.id &&
              savedCurrentSlide?.id &&
              slideDetails?.id !== savedCurrentSlide?.id &&
              savedNotPassQuiz &&
              check &&
              !check?.isPassedQuiz
            ) {
              handleChangeSlideWhenQuizFail()
            } else if (
              slideDetails?.id !== savedCurrentSlide?.id ||
              !savedCurrentSlide?.id
            ) {
              localStorage.setItem(
                'currentSlideId',
                JSON.stringify(slideDetails)
              )
            }

            isLogging.current = true
          }
        }, 500)

        return () => {
          clearTimeout(timeoutRef.current)
          isLogging.current = false
        }
      } else {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
          if (!isLogging.current) {
            const savedCurrentSlide = JSON.parse(
              localStorage.getItem('currentSlideId')
            )
            if (
              slideDetails?.id &&
              savedCurrentSlide?.id &&
              slideDetails?.id !== savedCurrentSlide?.id &&
              localStorage.getItem('countdownDeadline')
            ) {
              handleChangeSlide()
            } else if (
              (!localStorage.getItem('countdownDeadline') ||
                (slideDetails?.id && !savedCurrentSlide?.id)) &&
              slideDetails?.timer !== null &&
              !isComplete
            ) {
              localStorage.setItem(
                'currentSlideId',
                JSON.stringify(slideDetails)
              )
            }
            isLogging.current = true
          }
        }, 500)

        return () => {
          clearTimeout(timeoutRef.current)
          isLogging.current = false
        }
      }
    }
  }, [slideDetails])

  const timer = useMemo(() => slideDetails?.timer, [slideDetails])

  const totalSeconds =
    timer !== null
      ? timer?.hours * 3600 + timer?.minutes * 60 + timer?.seconds
      : 0

  const [deadline, setDeadline] = useState()

  useEffect(() => {
    const savedTime = localStorage.getItem('countdownDeadline')
    if (
      answerTimeOfQuiz &&
      answerTimeOfQuiz?.start_date &&
      !answerTimeOfQuiz?.end_date &&
      timer !== null &&
      wfaDomain
    ) {
      setDeadline(
        savedTime ? savedTime : new Date(Date.now() + totalSeconds * 1000)
      )
    }
  }, [timer, answerTimeOfQuiz, slideDetails])

  useEffect(() => {
    if (deadline) {
      localStorage.setItem('countdownDeadline', deadline)

      const interval = setInterval(() => {
        if (Math.floor((deadline - Date.now()) / 1000) <= 0) {
          clearInterval(interval)
          localStorage.removeItem('countdownDeadline')
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [deadline])

  const handleChangeSlide = () => {
    confirm({
      title:
        'You have not completed the current quiz! Do you want to complete it now?',
      cancelButtonProps: {
        style: {
          marginRight: '10px',
        },
      },
      okText: 'Complete',
      cancelText: 'Cancel',
      onOk() {
        handleEndTime()
      },
      okButtonProps: {
        style: {
          padding: '8px',
          outline: 'none',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '4px',
          backgroundColor: '#00122a',
          color: '#fff',
          fontSize: '1.4rem',
          lineHeight: '15px',
          whiteSpace: 'nowrap',
        },
      },

      onCancel() {
        const savedCurrentSlide = JSON.parse(
          localStorage.getItem('currentSlideId')
        )
        dispatch(actions.setSelectedSlide(savedCurrentSlide))
      },
    })
  }

  const handleChangeSlideWhenQuizFail = () => {
    confirm({
      title: 'You have not passed this quiz! Please retry now',
      cancelText: 'Ok',
      onCancel() {
        const savedCurrentSlide = JSON.parse(
          localStorage.getItem('currentSlideId')
        )
        dispatch(actions.setSelectedSlide(savedCurrentSlide))
      },
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      cancelButtonProps: {
        style: {
          padding: '8px',
          outline: 'none',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '4px',
          backgroundColor: '#00122a',
          color: '#fff',
          fontSize: '1.4rem',
          lineHeight: '15px',
          whiteSpace: 'nowrap',
        },
      },
    })
  }

  const isComplete =
    timer !== null && answerTimeOfQuiz && answerTimeOfQuiz?.end_date
      ? true
      : getIsCompleteQuiz(uniqueAnswers, questions, answerTimeOfQuiz)

  const isPassedQuiz = useMemo(() => {
    if (isComplete) {
      const totalCorrectAnswers =
        (uniqueAnswers &&
          uniqueAnswers?.filter((i) => i?.competent && i?.value)?.length) ||
        0
      const questionsLength =
        (questions && questions?.filter((i) => !i?.types)?.length) || 0
      const passGrade = slideDetails?.quiz_pass_rate
      if (passGrade !== null) {
        setPercentPass((totalCorrectAnswers / questionsLength) * 100)
        return (totalCorrectAnswers / questionsLength) * 100 >= passGrade
      } else {
        return true
      }
    }
  }, [questions, uniqueAnswers, isComplete, slideDetails])

  useEffect(() => {
    if (isBoolean(isPassedQuiz)) {
      const savedNotPassQuiz =
        JSON.parse(localStorage.getItem('notPassedQuiz')) || []
      const existingData = savedNotPassQuiz?.find(
        (i) => i?.id === slideDetails?.id
      )
      if (!existingData) {
        const newData = {
          id: slideDetails?.id,
          isPassedQuiz: isPassedQuiz,
        }
        localStorage.setItem(
          'notPassedQuiz',
          JSON.stringify([...savedNotPassQuiz, newData])
        )
      } else if (existingData?.isPassedQuiz !== isPassedQuiz) {
        existingData.isPassedQuiz = isPassedQuiz
        localStorage.setItem(
          'notPassedQuiz',
          JSON.stringify([...savedNotPassQuiz])
        )
      }
    }
  }, [isPassedQuiz])

  const notComplete =
    Array.isArray(uniqueAnswers) && uniqueAnswers.length < questions.length

  // useEffect(() => {
  //   if (
  //     wfaDomain &&
  //     !disableShowAnswer &&
  //     Array.isArray(uniqueAnswers) &&
  //     Array.isArray(questions) &&
  //     !isComplete
  //   ) {
  //     if (
  //       uniqueAnswers?.length === questions?.length &&
  //       uniqueAnswers?.some((i) => i?.value)
  //     ) {
  //       autoComplete()
  //     }
  //   }
  // }, [
  //   disableShowAnswer,
  //   uniqueAnswers,
  //   questions,
  //   answerTimeOfQuiz,
  //   isComplete,
  //   wfaDomain,
  // ])

  // const SaveAnswers = () => {
  //   if (questions && questions.length > 0) {
  //     console.log('🚀 ~ SaveAnswers ~ questions:', questions)
  //     for (let i = 0; i < questions.length; i++) {
  //       const question = questions[i]
  //       const answer = uniqueAnswers.find((answer) => {
  //         return answer.question_id === question.id
  //       })

  //       console.log('🚀 ~ answer ~ answer:', answer)
  //       if (!answer && question) {
  //         const params = {
  //           value: '',
  //           url: '',
  //           type: '',
  //           file_attachments: '',
  //           schedule_id: get(partners, '[0].schedule_id'),
  //           slide_id: question?.slide_id,
  //         }
  //         dispatch(postUserAnswer(params))
  //       }
  //     }
  //   }
  // }

  const autoComplete = async (isFromConfirmPopup = false) => {
    const savedCurrentSlide = JSON.parse(localStorage.getItem('currentSlideId'))
    const answerQuizId = isFromConfirmPopup
      ? getAnswerTimeBySlide(savedCurrentSlide, answerTime)?.id
      : get(answerTimeOfQuiz, 'id', '')

    // if (wfaDomain) {
    //   SaveAnswers()
    // }
    dispatch(
      putAnswerTime({
        endDate: dayjs(),
        answerQuizId,
        needToComplete: true,
      })
    )

    if (wfaDomain) {
      recursiveCall(0)
    }
  }

  const handleClickFileDoc = () => {
    if (hiddenFileDoc.current) {
      hiddenFileDoc.current.click()
    }
  }

  const handleSave = () => {
    if (isActiveTextbox) {
      handleSubmit(null, false, false)
      setIsActiveTextbox(false)
    }
  }

  const { ref } = useClickOutside(handleSave)

  const resetField = () => {
    setVideoUrl('')
    setText('')
    setError(null)
    setChooseOptions([])
    setFiles([])
    setChooseCorrectOptions(null)
  }

  useEffect(() => {
    if (selectedQuiz && Array.isArray(uniqueAnswers)) {
      resetField()
      const isAnswered = uniqueAnswers.find(
        (item) => item.question_id === selectedQuiz.id
      )
      if (isAnswered) {
        if (get(isAnswered, 'value')) {
          if (multipleChoices) {
            setChooseCorrectOptions(get(isAnswered, 'competent', null))
            setChooseOptions(parseSafe(isAnswered.value))
          } else {
            setText(isAnswered.value)
          }
        }
        if (get(isAnswered, 'url')) {
          setVideoUrl(isAnswered.url)
        }

        if (get(isAnswered, 'file_attachments', '')) {
          const filesJSON = parseSafe(get(isAnswered, 'file_attachments', ''))

          let file = null
          if (get(isAnswered, 'attachments', false)) {
            const attachments = get(isAnswered, 'attachments', [])
            const fileType = get(
              get(attachments, 'mimetype', '').split('/'),
              '[0]',
              ''
            )
            const attachmentName = get(isAnswered, 'attachment_name')
            const url = `${config.baseUrl}/web/content/${attachments.id}?download=true`

            file = {
              name: attachmentName,
              type: fileType,
              url,
            }
          }

          if (file) {
            const update = uniqBy([...filesJSON, file], 'name')
            setFiles(update)
          } else {
            setFiles(filesJSON)
          }
        }
      } else {
        resetField()
      }
    }
  }, [selectedQuiz, uniqueAnswers])

  useEffect(() => {
    if (slideDetails) {
      dispatch(setSelectedQuiz(null))
    }
  }, [slideDetails])

  const handleChangeFileDoc = async (event) => {
    dispatch(showGlobalLoading())
    const multipleFiles = [...event.target.files]
    if (multipleFiles.length === 0) {
      dispatch(hideGlobalLoading())
      return
    }

    const cloneFiles = [...files]

    if (cloneFiles.length > 0) {
      cloneFiles.forEach((file, id) => {
        let isExistFile = multipleFiles.findIndex(
          (item) => item.name === file.name
        )

        const name = get(multipleFiles, `[${isExistFile}].name`, '')
        if (isExistFile !== -1) {
          showError(`This file ${name} is exist`)
          multipleFiles.splice(isExistFile, 1)
        }
      })
    }

    if (multipleFiles.length === 0) {
      dispatch(hideGlobalLoading())
      return
    }

    multipleFiles.forEach((file, id) => {
      if (!SUPPORTED_ALL_FORMATS.includes(get(file, 'type', ''))) {
        showError(
          `This file ${file.name} isn't a supported type (Only .pdf, .zip, .docx, .png and .jpg supported)`
        )
        multipleFiles.splice(id, 1)
      }
    })

    const requests = []

    multipleFiles.forEach((file) => {
      const type = file.type
      if (file.size > 20971520) {
        return showError(`Sorry, your file ${file.name} is too large.`)
      }

      if (SUPPORTED_FORMATS.includes(type)) {
        requests.push(uploadImage(file))
      } else if (SUPPORTED_FORMATS_DOCUMENT.includes(type)) {
        requests.push(uploadPdf(file))
      }
    })

    const resFiles = await Promise.all(requests)
    dispatch(hideGlobalLoading())
    setFiles([
      ...files,
      ...resFiles.map((file) => ({
        name: file.name,
        type: file.type,
        url: file.url,
      })),
    ])
  }

  useEffect(() => {
    if (selectedQuiz && answerTime) {
      dispatch(loadUserAnswer())
    }
  }, [selectedQuiz, answerTime])

  const handlePickOption = (option) => {
    if (isComplete) {
      return
    }
    const existOption = chooseOptions.find(
      (item) => item.order === option.order
    )

    if (existOption) {
      const newOptions = chooseOptions.filter(
        (item) => item.order !== option.order
      )
      return setChooseOptions(newOptions)
    }

    if (correctAnswersLength === 1) {
      const newOptions = [{ order: option.order }]
      return setChooseOptions(newOptions)
    }

    if (correctAnswersLength === 2) {
      if (chooseOptions.length < 2) {
        const newOptions = [...chooseOptions, { order: option.order }]
        setChooseOptions(newOptions)
      } else {
        const newOptions = [
          { order: get(chooseOptions, '[1].order', '') },
          { order: option.order },
        ]
        setChooseOptions(newOptions)
      }
      return
    }

    if (correctAnswersLength === 3) {
      if (chooseOptions.length < 3) {
        const newOptions = [...chooseOptions, { order: option.order }]
        setChooseOptions(newOptions)
      } else {
        const newOptions = [
          { order: get(chooseOptions, '[1].order', '') },
          { order: get(chooseOptions, '[2].order', '') },
          { order: option.order },
        ]
        setChooseOptions(newOptions)
      }
      return
    }

    if (option) {
      const existOption = chooseOptions.find(
        (item) => item.order === option.order
      )
      if (existOption) {
        const newOptions = chooseOptions.filter(
          (item) => item.order !== option.order
        )
        setChooseOptions(newOptions)
      } else {
        const newOptions = [...chooseOptions, { order: option.order }]
        setChooseOptions(newOptions)
      }
    }
  }

  const renderAnswer = (type) => {
    let content = null

    if (!type && multipleChoices) {
      return (
        <div>
          <ListChoiceStudent
            listChoice={multipleChoices}
            chooseOptions={chooseOptions}
            handlePickOption={handlePickOption}
            chooseCorrectOptions={chooseCorrectOptions}
            correctAnswers={correctAnswers}
            hideAnswer={correctAnswers?.length === 0 || disableShowAnswer}
            isCorrectAnswer={isCorrectAnswer}
          />
          {chooseCorrectOptions !== null && (
            <FinalResult
              result={chooseCorrectOptions}
              correctAnswers={correctAnswers}
              isCorrectAnswer={isCorrectAnswer}
              hideAnswer={disableShowAnswer}
            />
          )}
        </div>
      )
    }
    switch (type) {
      case TYPE_ANSWER.TEXT:
        content = (
          <>
            <div key={type.id} style={{ marginBottom: 25 }}>
              <div style={{ color: '#4F5878' }}>Type Your Answer</div>
              <div style={{ marginBottom: 8 }} />
              <div ref={ref}>
                <TextArea
                  className="add-course__textarea mgbt-0"
                  id="description"
                  name="description"
                  allowClear
                  cols="30"
                  rows="6"
                  placeholder="Type something"
                  value={text}
                  disabled={isComplete}
                  onChange={(e) => {
                    setIsActiveTextbox(true)
                    if (!!error) {
                      setError(null)
                    }
                    setText(e.currentTarget.value)
                  }}
                  minLength={!isBellDomain || files?.length > 0 ? null : 5}
                />
              </div>
            </div>
          </>
        )
        break
      case TYPE_ANSWER.ATTACHMENT:
        content = (
          <div key={type.id} style={{ marginBottom: 25 }}>
            <div style={{ marginBottom: 10, color: '#4F5878' }}>
              Upload multiple files
            </div>
            <div style={{ color: '#4F5878' }}>Your files: </div>
            <PreviewFilesUploadQuiz
              files={files}
              setFiles={setFiles}
              isComplete={isComplete}
            />
            <div style={{ marginBottom: 8 }} />
            <div className="add-lesson">
              <div
                className="add-lesson__upload"
                onClick={!isComplete ? handleClickFileDoc : () => {}}
              >
                <img src={images.admin.document_upload} alt="" />

                <div className="add-lesson__text" style={{ color: '#4F5878' }}>
                  Click here to upload files
                </div>

                <input
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  ref={hiddenFileDoc}
                  onChange={handleChangeFileDoc}
                />
              </div>

              <div
                className="add-lesson__button"
                style={{
                  position: 'absolute',
                  right: '20px',
                }}
              >
                <button
                  className={`tertiary-btn ${isComplete ? 'disable-btn' : ''}`}
                  type="button"
                  disabled={isComplete}
                  onClick={() => hiddenFileDoc.current.click()}
                  style={{ padding: '7px 20px', width: 90 }}
                >
                  Browse
                </button>
              </div>
            </div>
          </div>
        )
        break
      case TYPE_ANSWER.URL:
        content = (
          <div key={type.id} style={{ marginBottom: 25 }}>
            <div style={{ color: '#4F5878' }}>Share a link</div>
            <div style={{ marginBottom: 8 }} />
            <input
              type="text"
              name="videoUrl"
              id="videoUrl"
              placeholder="http://"
              className="add-course__input"
              value={videoUrl}
              disabled={isComplete}
              onChange={(e) => {
                setVideoUrl(e.currentTarget.value)
              }}
            />
          </div>
        )
        break
      default:
        break
    }
    return content
  }

  const handleSendEmail = async () => {
    const courseName = get(course, 'name', '')

    const email = get(currentUser, 'username', '')
    const userName = get(currentUser, 'name')

    try {
      const requestUrl = `${config.api}/send-mail/complete-quiz`
      await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mail: email,
          courseName: courseName,
          userName,
        }),
      })

      localStorage.removeItem('percentComplete')
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSubmit = async (
    e,
    needToNext,
    preventLoading = false,
    isAutoComplete
  ) => {
    if (e) {
      e.preventDefault()
    }
    if (!isEnroll && !get(partners, '[0].schedule_id')) {
      return showError('You are not enroll this course yet!')
    }
    const isAnswered = uniqueAnswers.find(
      (item) => item.question_id === selectedQuiz.id
    )

    const savedItems = JSON.parse(localStorage.getItem('submittedAnswer'))
    const isSubmittedOtherTab = !!savedItems?.find(
      (i) => i?.question_id === selectedQuiz?.id
    )

    if (isSubmittedOtherTab && !isAnswered) {
      return setError(
        <span>
          Please click{' '}
          <Button
            type="link"
            onClick={() => window.location.reload()}
            style={{ padding: 0 }}
          >
            here
          </Button>{' '}
          to refresh the page because you submitted an answer to this question
          on another tab!
        </span>
      )
    }
    if (multipleChoices && chooseOptions && correctAnswers) {
      const isValidChoices = chooseOptions.length === correctAnswers.length
      if (correctAnswers?.length > 0 && !isValidChoices) {
        return showError(
          `You need to select ${correctAnswers.length} ${
            correctAnswers.length > 1 ? 'answers' : 'answer'
          } to submit!`
        )
      }
    }

    const types = getTypesSubmit(text, videoUrl, files)

    let params = {
      value: multipleChoices
        ? JSON.stringify(orderBy(chooseOptions, 'order', 'asc'))
        : text,
      url: videoUrl,
      type: types,
      file_attachments: files?.length > 0 ? JSON.stringify(files) : '',
      schedule_id: get(partners, '[0].schedule_id'),
    }

    if (isAnswered) {
      params.id = get(isAnswered, 'id')
      if (isAnswered.attachments && !params.attachment) {
        delete params.attachment
      }
    } else {
      delete params.id
    }
    if (
      [params?.value, params?.file_attachments, params?.url].every((x) => !x)
    ) {
      return setError('You need to submit at least one answer.')
    }
    if (!multipleChoices && !files?.length && params?.value.length < 5) {
      return setError('Answer must be minimum 5 characters.')
    }

    dispatch(postUserAnswer(params, needToNext, preventLoading, isAnswered))

    if (!wfaDomain && isAutoComplete) {
      const complete = uniqueAnswers.length >= questions.length
      if (
        complete &&
        get(answerTimeOfQuiz, 'id') &&
        !get(answerTimeOfQuiz, 'end_date')
      ) {
        const answerQuizId = get(answerTimeOfQuiz, 'id', '')

        dispatch(
          showConfirmQuizModal({
            answerQuizId,
            isLastAnswer,
            userLogByCourse,
          })
        )
      }
    }

    // if (wfaDomain && isLastQuestion) {
    //   await handleSendEmail()
    // }

    // if (isAutoComplete) autoComplete()
    if (disableShowAnswer) {
      handleNextQuestion()
    }

    resetField()
  }

  const renderAllQuestion = (question) => {
    let className = `quiz__item ${
      selectedQuiz && selectedQuiz.id === question.id ? 'quiz__item-active' : ''
    } `
    const isAnswered = uniqueAnswers.find(
      (answer) => +answer.question_id === +question.id
    )

    const isCorrectAnswer =
      isAnswered?.type === '' ? isAnswered?.competent : isAnswered?.value

    const isEmptyAnswer =
      isAnswered?.value === '' &&
      isAnswered?.url === '' &&
      !isAnswered.attachments &&
      !parseSafe(get(isAnswered, 'file_attachments'))

    if (isAnswered && !isEmptyAnswer) {
      className += 'quiz__item__answered'
      // className += isCorrectAnswer ? 'quiz__item__answered' : ''
      // 'quiz__item__answered_incorrect'
    }

    return className
  }

  const handleSetTimeQuiz = () => {
    if (!isComplete && !answerTimeOfQuiz) {
      dispatch(putAnswerTime({ startDate: dayjs(), needToComplete: false }))
    }
  }
  const getIndexQuiz = () => {
    if (!Array.isArray(questions) && !selectedQuiz) {
      return ''
    }
    const index = questions.findIndex((item) => item.id === selectedQuiz.id)
    return index + 1
  }

  const handleComplete = () => {
    if (!Array.isArray(uniqueAnswers) && !Array.isArray(questions)) {
      return 'Something might wrong, please try again later!'
    }

    const complete = uniqueAnswers.length >= questions.length
    if (
      complete &&
      get(answerTimeOfQuiz, 'id') &&
      !get(answerTimeOfQuiz, 'end_date')
    ) {
      const answerQuizId = get(answerTimeOfQuiz, 'id', '')
      dispatch(showConfirmQuizModal({ answerQuizId }))
    } else {
      showError('You need to answer all the questions!')
    }
  }

  const handleSelectQuiz = (quiz) => {
    dispatch(setSelectedQuiz(quiz))
  }

  const renderHead = () => {
    if (multipleChoices) {
      return correctAnswers.length > 1
        ? `Select ${correctAnswers.length} answers`
        : 'Select an answer'
    }
    return 'Your answer(s)'
  }

  const handleNextQuestion = () => {
    const current = questions.findIndex(
      (question) => question.id === selectedQuiz.id
    )
    if (current + 1 <= questions.length - 1) {
      dispatch(setSelectedQuiz(questions[current + 1]))
    }
  }

  const renderButtonNext = () => {
    const almostComplete =
      uniqueAnswers &&
      questions &&
      uniqueAnswers?.length >= questions?.length - 1

    const isHideButtonComplete =
      uniqueAnswers?.length >= questions?.length
        ? uniqueAnswers?.find(
            ({ value, url, file_attachments }) =>
              !value && !url && !file_attachments
          )
        : true
    const completed =
      uniqueAnswers && questions && uniqueAnswers?.length === questions?.length

    const isAnswered = uniqueAnswers?.find(
      (answer) => +answer.question_id === +selectedQuiz.id && answer?.value
    )

    if (multipleChoices) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {chooseCorrectOptions === null ||
            (isCorrectAnswer && !chooseCorrectOptions) ? (
              <button
                className={`btn btn-primary ${
                  !answerTimeOfQuiz || isComplete ? 'disable-btn' : ''
                }`}
                onClick={(e) => handleSubmit(e, false)}
                disabled={!answerTimeOfQuiz || isComplete ? true : false}
              >
                <span>Confirm</span>
              </button>
            ) : null}
          </div>
          {(isCorrectAnswer && chooseCorrectOptions) ||
          (!isCorrectAnswer && isAnswered) ? (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() =>
                !isHideButtonComplete ? autoComplete() : handleNextQuestion()
              }
            >
              <span>{!isHideButtonComplete ? 'Complete' : 'Next'}</span>
              <img
                src={images.course.arrow_right_active}
                alt=""
                style={{ marginLeft: 6, marginBottom: 5 }}
              />
            </button>
          ) : null}
        </div>
      )
    }
    return (
      <Space>
        <button
          className={`btn btn-primary ${
            !answerTimeOfQuiz || isComplete ? 'disable-btn' : ''
          }`}
          onClick={(e) => handleSubmit(e, true, false, false)}
          disabled={!answerTimeOfQuiz || isComplete ? true : false}
        >
          <span className="save-and-next">Save</span>
          <img
            src={images.course.arrow_right_active}
            alt=""
            style={{ marginLeft: 6, marginBottom: 5 }}
          />
        </button>
        {isLastQuestion && almostComplete ? (
          <button
            className={`btn btn-primary ${
              !answerTimeOfQuiz || isComplete ? 'disable-btn' : ''
            }`}
            onClick={(e) =>
              handleSubmit(
                e,
                !almostComplete || isAnswered,
                false,
                (!isAnswered && almostComplete) || completed
              )
            }
            disabled={!answerTimeOfQuiz || isComplete ? true : false}
          >
            <span className="save-and-next">Save & Complete</span>
            <img
              src={images.course.arrow_right_active}
              alt=""
              style={{ marginLeft: 6, marginBottom: 5 }}
            />
          </button>
        ) : null}
      </Space>
    )
  }

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return
      }

      const id = get(children, '[0].attribs.src', '')
      const subId = id.substr(id.length - 8)

      if (attribs.class === 'image') {
        const active = get(activeImage, `[${subId}]`, false)
        return (
          <div
            className={classNames({
              active_image: active,
            })}
            onClick={() => handleClickImage(subId)}
          >
            {active && (
              <div
                className="backdrop"
                onClick={() => handleClickImage(subId)}
              />
            )}
            {domToReact(children, options)}
          </div>
        )
      }
    },
  }

  const handleClickImage = (id) => {
    let activeImageClone = {}
    activeImageClone[id] = !activeImage[id]
    setActiveImage(activeImageClone)
  }

  const sortQuestionType = (types) => {
    const sortingArr = ['text', 'url', 'attachment']
    const arrTypes = types?.split(',')?.filter((x) => x)
    const items = arrTypes.sort(function (a, b) {
      return sortingArr.indexOf(a) - sortingArr.indexOf(b)
    })

    return Array.isArray(items) && items?.length ? items : ['']
  }

  const handleEndTime = () => {
    autoComplete()
  }
  return (
    <div className="channel" style={{ height: 'unset', minHeight: '100vh' }}>
      <div className="channel__head" style={{ paddingTop: 11 }}>
        <div className="channel__head__left" style={{ flex: 1 }}>
          <div className="channel__title" style={{ paddingRight: 25 }}>
            {get(slideDetails, 'name', '')} {`(${questions.length} questions) `}
          </div>
        </div>
        <div className="channel__head__right" style={{ alignItems: 'center' }}>
          {isComplete && (
            <div className="quiz__complete">
              Great job! You have completed the quiz.{' '}
              <img src={images.header.check} alt="" />
            </div>
          )}
          {isShowTimer ? (
            !isComplete && !answerTimeOfQuiz ? (
              <BtnPrimary
                name="Start Quiz"
                style={{
                  backgroundColor: answerTimeOfQuiz ? '#DEE2ED' : '#042580',
                  fontWeight: 'bold',
                  padding: '12.5px 20px',
                }}
                handleClick={handleSetTimeQuiz}
              />
            ) : (
              !isComplete && (
                <div style={{ marginRight: '10px' }}>
                  <Countdown
                    value={deadline}
                    onFinish={() => handleEndTime()}
                  />
                  <div>Time remaining</div>
                </div>
              )
            )
          ) : (
            !isComplete && (
              <BtnPrimary
                name="Start Quiz"
                style={{
                  backgroundColor: answerTimeOfQuiz ? '#DEE2ED' : '#042580',
                  fontWeight: 'bold',
                  padding: '12.5px 20px',
                }}
                handleClick={handleSetTimeQuiz}
              />
            )
          )}
          {/* {!isComplete && (
            <BtnPrimary
              name="Start Quiz"
              style={{
                backgroundColor: answerTimeOfQuiz ? '#DEE2ED' : '#042580',
                fontWeight: 'bold',
                padding: '12.5px 20px',
              }}
              handleClick={handleSetTimeQuiz}
            />
          )} */}
          {disableShowAnswer && (
            <div className="channel__head__right__button">
              {isComplete ? null : answerTimeOfQuiz ? (
                <button
                  type="primary"
                  className={`primary-btn ${notComplete ? 'grey-out' : ''}`}
                  onClick={handleComplete}
                  style={{ marginLeft: isMobile ? 0 : 10 }}
                >
                  <span className="btn__text">Complete Quiz</span>
                  <img
                    src={
                      notComplete
                        ? images.header.check_inactive
                        : images.header.check_red
                    }
                    alt=""
                  />
                </button>
              ) : null}
            </div>
          )}
          <div className="channel__head__right__cta">
            <div
              className="channel__head__right__cta__back-btn"
              onClick={() => handlePreviousSlide(true)}
            >
              <img src={images.channel.angle_left} alt="" />
            </div>
            <div
              className="channel__head__right__cta__next-btn"
              onClick={() => handleNextSlide(true)}
            >
              <img src={images.channel.angle_right} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        {!answerTimeOfQuiz ? (
          <div className="transparent">
            <p>To start the quiz click on the Start Quiz button above!</p>
          </div>
        ) : isComplete && wfaDomain && !visibleQuiz ? (
          isPassedQuiz ? (
            <CompletePage
              slideDetails={slideDetails}
              percentPass={percentPass}
            />
          ) : (
            <FailPage
              slideDetails={slideDetails}
              allAnswer={allAnswer}
              percentPass={percentPass}
              answerTimeOfQuiz={answerTimeOfQuiz}
            />
          )
        ) : (
          <div>
            <div className="quiz__all-question-wrap">
              <div className="quiz__all-question">
                {questions &&
                  uniqueAnswers &&
                  questions.map((question, i) => {
                    return (
                      <div
                        key={i}
                        className={renderAllQuestion(question)}
                        onClick={() => {
                          setChooseOptions([])
                          handleSelectQuiz(question)
                          dispatch(preventLoadRelatedApi(true))
                          dispatch(loadCourse(id, false, false))
                        }}
                      >
                        {i + 1}
                      </div>
                    )
                  })}
              </div>
              <div>
                {wfaDomain && isComplete && visibleQuiz && (
                  <div
                    style={{
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      marginLeft: 20,
                    }}
                    onClick={() => setVisibleQuiz(!visibleQuiz)}
                  >
                    show quiz{' '}
                    {visibleQuiz ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </div>
                )}
              </div>
            </div>

            <form>
              <div
                className={`${isMobile ? 'quiz__mobile__body' : 'quiz__body'}`}
              >
                {selectedQuiz ? (
                  isMobile ? (
                    <>
                      <div className="quiz__left quiz__mobile__left">
                        <div className="quiz__question">
                          Question {getIndexQuiz()}
                        </div>
                        <div className="quiz__question-des">
                          <CKEditorWrapper>
                            {parseHtml(selectedQuiz.question, options)}
                          </CKEditorWrapper>
                        </div>
                      </div>
                      {!isMobile && <div className="quiz__vertical__line" />}
                      <div className="quiz__right quiz__mobile__right">
                        <div className="quiz__question">{renderHead()}</div>
                        <div className="quiz__answers">
                          {sortQuestionType(selectedQuiz.types).map(
                            (type, i) => {
                              return <div key={i}>{renderAnswer(type)}</div>
                            }
                          )}
                        </div>
                        {renderButtonNext()}
                      </div>
                    </>
                  ) : (
                    <div
                      className="inner_container"
                      style={{ display: 'flex' }}
                    >
                      <div
                        className="quiz__left"
                        style={{
                          borderRight: '1px solid rgb(208, 202, 216)',
                          width: 700,
                        }}
                      >
                        <div className="quiz__question">
                          Question {getIndexQuiz()}
                        </div>
                        <div className="quiz__question-des">
                          <CKEditorWrapper>
                            {parseHtml(selectedQuiz.question, options)}
                          </CKEditorWrapper>
                        </div>
                      </div>

                      <div className="quiz__right">
                        {error && (
                          <Alert
                            message={error}
                            type="error"
                            style={{ marginBottom: 15 }}
                          />
                        )}
                        <div className="quiz__question">{renderHead()}</div>
                        <div className="quiz__answers">
                          {sortQuestionType(selectedQuiz.types).map(
                            (type, i) => {
                              return <div key={i}>{renderAnswer(type)}</div>
                            }
                          )}
                        </div>
                        {renderButtonNext()}
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    No quiz for now, please select question!
                  </div>
                )}
              </div>
            </form>
          </div>
        )}
        {wfaDomain && isComplete && !visibleQuiz && (
          <div
            style={{
              position: 'absolute',
              right: 20,
              top: 20,
              cursor: 'pointer',
            }}
            onClick={() => setVisibleQuiz(!visibleQuiz)}
          >
            show quiz {visibleQuiz ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </div>
        )}
      </div>
    </div>
  )
}

export default QuizLesson
