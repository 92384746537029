import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Dropdown from 'react-dropdown'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { DatePicker, Radio, Switch, TimePicker, Modal, Checkbox } from 'antd'

import images from 'Themes/Images'
import Input from 'component/Input'
import InputSmall from 'component/InputSmall'
import BtnPrimary from 'component/BtnPrimary'
import * as adminActions from 'container/Admin/actions'
import { ERROR_MESSAGE } from 'utils/constants'
import { hideFormSchedule } from '../../actions'
import { makeSelectZooms } from 'container/Admin/selectors'
import { makeSelectCourse } from 'container/Home/selectors'
import { initialHourBreak, initialDay } from 'utils/constants'
import { isCustomType, mapListZoomsToDropdown, checkInput } from 'utils/helper'
import GenerateZoomLink from 'container/Admin/components/ChannelDetails/components/GenerateZoomLink'
import { isScheduleAlwaysAvailable } from 'utils/helper'
import './styles.scss'
import { saveZoomLink } from '../../../Admin/actions'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}

const ScheduleSchema = Yup.object().shape({
  startDate: Yup.string().required(ERROR_MESSAGE),
  endDate: Yup.string().required(ERROR_MESSAGE),
  startTime: Yup.string().required(ERROR_MESSAGE),
  endTime: Yup.string().required(ERROR_MESSAGE),
  class_code: Yup.string().required(ERROR_MESSAGE),
})

const format = 'HH:mm'
const formatDate = 'YYYY-MM-DD'

const FormScheduleModal = ({ showFormSchedule, dispatch, schedule, page }) => {
  const zooms = useSelector(makeSelectZooms())
  const course = useSelector(makeSelectCourse())
  const scheduleId = get(schedule, 'id')
  const meetings = get(course, 'meeting', [])
  const meeting =
    meetings && meetings.find((item) => item.schedule_id === scheduleId)

  const maxSizeDefault = get(course, 'max_size', 0)
  const [checkedAlways, setCheckedAlways] = useState(false)

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      hourBreak: '1',
      scheduleType: 'daily',
      venue: 'online',
      active: false,
      custom: [],
      location: '',
      zoom: '',
      class_code: '',
      max_size: 0,
      password: '',
      meeting_option: false,
      meeting_option_2: false,
      url: '',
      start_url: '',
      conference_url: '',
      course_run_id: '',
    },
    validationSchema: ScheduleSchema,
    onSubmit: (values) => {
      const body = {
        venue: values.venue === 'online' ? values.venue : values.location,
        end_time: dayjs(values.endTime).format(format),
        start_time: dayjs(values.startTime).format(format),
        end_date: dayjs(values.endDate).format(formatDate),
        start_date: dayjs(values.startDate).format(formatDate),
        hour_break: parseFloat(values.hourBreak),
        schedule_type:
          values.scheduleType === 'custom'
            ? values.custom.join(', ')
            : values.scheduleType,
        active: values.active,
        zoom_id: get(values, 'zoom.value', 0),
        class_code: values.class_code,
        course_run_id: values.course_run_id,
        max_size: parseInt(values.max_size),
      }

      if (!schedule) {
        dispatch(adminActions.addSchedule(body, page, handleResetField))
      } else {
        dispatch(
          adminActions.addSchedule(
            { ...body, id: schedule.id },
            page,
            handleResetField
          )
        )
      }

      if (meeting) {
        dispatch(
          saveZoomLink({
            zoom: values.zoom,
            joinUrl: values.url,
            startUrl: values.start_url,
            schedule,
            password: values.password,
            meetingId: get(meeting, 'meeting_id', ''),
            zoomId: get(meeting, 'zoom_id'),
            conferenceUrl: values.conference_url,
            meeting_option: values.meeting_option,
            meeting_option_2: values.meeting_option_2,
          })
        )
      }
    },
  })

  const handleResetField = () => {
    formik.resetForm({})
  }

  useEffect(() => {
    dispatch(adminActions.loadZooms())
  }, [])

  useEffect(() => {
    if (meeting && zooms) {
      const zoom =
        zooms && zooms.find((item) => item.id === get(meeting, 'zoom_id'))
      formik.setFieldValue('zoom', {
        value: get(meeting, 'zoom_id'),
        label: get(zoom, 'email', ''),
      })
      formik.setFieldValue('password', get(meeting, 'password'))
      formik.setFieldValue('meeting_option', get(meeting, 'join_before_host'))
      formik.setFieldValue('meeting_option_2', get(meeting, 'waiting_room'))
      formik.setFieldValue('url', get(meeting, 'url', ''))
      formik.setFieldValue('start_url', get(meeting, 'start_url', ''))
      formik.setFieldValue('conference_url', get(meeting, 'conference_url', ''))
    }
  }, [meeting, zooms])

  useEffect(() => {
    if (schedule) {
      const zoom_id = get(schedule, 'zoom_id', 0)
      const startDate = get(schedule, 'start_date')
      const endDate = get(schedule, 'end_date')
      const isAlwaysAvailable = isScheduleAlwaysAvailable(startDate, endDate)
      setCheckedAlways(isAlwaysAvailable)
      formik.setFieldValue('startDate', dayjs(startDate))
      formik.setFieldValue('endDate', dayjs(endDate))
      formik.setFieldValue('startTime', dayjs(startDate + schedule.start_time))
      formik.setFieldValue('endTime', dayjs(endDate + schedule.end_time))
      formik.setFieldValue('max_size', get(schedule, 'max_size', 0))
      const hourBreak = initialHourBreak.find(
        (item) => parseInt(item.value) === get(schedule, 'hour_break')
      )
      formik.setFieldValue('hourBreak', hourBreak)
      formik.setFieldValue('class_code', get(schedule, 'class_code', ''))
      formik.setFieldValue('course_run_id', get(schedule, 'course_run_id', ''))

      if (Array.isArray(zooms)) {
        const zoom = zooms.find((item) => item.id === zoom_id)
        if (zoom) {
          formik.setFieldValue('zoom', { value: zoom.id, label: zoom.email })
        }
      }
      formik.setFieldValue('active', schedule.active)
      const isCustom = isCustomType(schedule.schedule_type)
      if (isCustom) {
        formik.setFieldValue('scheduleType', 'custom')
        formik.setFieldValue('custom', schedule.schedule_type.split(','))
      } else {
        formik.setFieldValue('scheduleType', schedule.schedule_type)
      }

      if (schedule.venue !== 'online') {
        formik.setFieldValue('venue', 'in-campus')
        formik.setFieldValue('location', schedule.venue)
      } else {
        formik.setFieldValue('venue', schedule.venue)
      }
    } else {
      if (maxSizeDefault) {
        formik.setFieldValue('max_size', maxSizeDefault)
      }
    }
  }, [schedule])

  const handleCancel = () => {
    if (schedule) {
      handleResetField()
    }
    dispatch(hideFormSchedule())
  }

  const handleChangeVenue = (e) => {
    formik.setFieldValue('venue', e.target.value)
  }

  const onChangeRadio = (e) => {
    formik.setFieldValue('scheduleType', e.target.value)
  }

  const handleSelectHour = (value) => {
    formik.setFieldValue('hourBreak', value.value)
  }

  const renderIcon = () => {
    if (schedule) {
      return images.header.check
    }

    return isValid ? images.admin.plus : images.admin.plus_white
  }

  const calculateDateBetween = () => {
    const startDate = dayjs(formik.values.startDate)
    const endDate = dayjs(formik.values.endDate)

    if (startDate.isValid() && endDate.isValid()) {
      const diff = endDate.diff(startDate, 'days')
      if (diff < 0) {
        return 'invalid days!'
      }

      return diff + 1 + ' days'
    }
  }

  const handleDeleteSchedule = () => {
    if (schedule) {
      dispatch(adminActions.deleteSchedule(schedule.id, page))
      handleCancel()
    }
  }

  const handleSelectedDay = (value) => {
    const isExist = formik.values.custom.find((item) => item === value.label)
    if (isExist) {
      formik.setFieldValue(
        'custom',
        formik.values.custom.filter((item) => item !== value.label)
      )
    } else {
      formik.setFieldValue('custom', [...formik.values.custom, value.label])
    }
  }

  const handleSelectZoomAccount = (value) => {
    formik.setFieldValue('zoom', value)
  }

  const handleChangeLocation = (e) => {
    if (formik.values.venue === 'in-campus') {
      formik.setFieldValue('location', e.currentTarget.value)
    }
  }

  const handleChangeMaxSizeClass = (e) => {
    if (checkInput(e)) {
      formik.setFieldValue('max_size', e.currentTarget.value)
    }
  }

  const isValid =
    formik.values.startDate &&
    formik.values.endDate &&
    formik.values.startTime &&
    formik.values.endTime &&
    formik.values.class_code

  const handleCheckAlwaysAvailable = ({ target }) => {
    const checked = target.checked
    setCheckedAlways(checked)

    if (checked) {
      const startDate = dayjs().format(formatDate)
      const endDate = dayjs().add(10, 'year').format(formatDate)
      const startTime = '00:00'
      const endTime = '00:00'
      formik.setFieldValue('startDate', dayjs(startDate))
      formik.setFieldValue('endDate', dayjs(endDate))
      formik.setFieldValue('startTime', dayjs(startDate + startTime))
      formik.setFieldValue('endTime', dayjs(endDate + endTime))
    } else {
      formik.setFieldValue('startDate', '')
      formik.setFieldValue('endDate', '')
      formik.setFieldValue('startTime', '')
      formik.setFieldValue('endTime', '')
    }
  }

  return (
    <Modal
      visible={showFormSchedule}
      footer={null}
      onCancel={handleCancel}
      className="sc-modal"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="sc-modal__header">
          <div className="sc-modal__heading">
            {schedule ? 'Edit schedule' : 'Add a schedule'}
          </div>
          <div className="sc-modal__text">
            {schedule ? 'Edit' : 'Set'} the date and time this course would be
            available.
          </div>
        </div>

        <Checkbox onChange={handleCheckAlwaysAvailable} checked={checkedAlways}>
          Always Available
        </Checkbox>
        <div className="sc-modal__set-active" style={{ width: '45%' }}>
          <div className="sc-modal__label switch_wrap">
            <Input
              label="CLASS CODE"
              name="class_code"
              className="mg-small"
              placeHolder="Enter class code"
              value={formik.values.class_code}
              formik={formik}
              onChange={formik.handleChange}
            />
            <Input
              label="Course Run ID"
              name="course_run_id"
              className="mg-small"
              placeHolder="Enter Course Run ID"
              value={formik.values.course_run_id}
              formik={formik}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <div className="sc-modal__main">
          <div className="sc-modal__select-date">
            <div className="sc-modal__date">
              <div className="sc-modal__label">START DATE*</div>
              <DatePicker
                onChange={(date, dateString) => {
                  formik.setFieldValue('startDate', date)
                }}
                value={formik.values.startDate}
                placeholder="Add start date"
                className="sc-modal__datepicker"
              />
              {formik.touched.startDate && formik.errors.startDate && (
                <div className="form__error">{formik.errors.startDate}</div>
              )}
            </div>
            <div className="sc-modal__date">
              <div className="sc-modal__label">END DATE*</div>
              <DatePicker
                value={formik.values.endDate}
                onChange={(date, dateString) => {
                  formik.setFieldValue('endDate', date)
                }}
                placeholder="Add end date"
                className="sc-modal__datepicker"
              />
              {formik.touched.endDate && formik.errors.endDate && (
                <div className="form__error">{formik.errors.endDate}</div>
              )}
            </div>
            {schedule && (
              <div className="sc-modal__number">{calculateDateBetween()}</div>
            )}
          </div>
          <div style={{ marginBottom: 32 }}>
            {/* <Radio value={1}>Include public holidays in Singapore</Radio> */}
          </div>
          <div className="sc-modal__select-day">
            <div className="sc-modal__label">day*</div>
            <Radio.Group
              onChange={onChangeRadio}
              value={formik.values.scheduleType}
              className="sc-modal__select-option"
            >
              <div className="mgR-100 mgR-0">
                <Radio value="daily" style={radioStyle}>
                  Daily
                </Radio>
                <Radio value="weekday" style={radioStyle}>
                  Weekdays
                </Radio>
                <Radio value="weekends" style={radioStyle}>
                  Weekends
                </Radio>
              </div>
              <div style={{ maxWidth: 200 }}>
                <Radio value="custom">Custom schedule</Radio>
                <div style={{ marginTop: 8, marginLeft: 25 }}>
                  <Dropdown
                    options={initialDay}
                    onChange={handleSelectedDay}
                    value={
                      formik.values.custom && formik.values.custom.length !== 0
                        ? `${formik.values.custom.length} selected`
                        : ''
                    }
                    disabled={!!!(formik.values.scheduleType === 'custom')}
                    placeholder="Select day(s)"
                    className="dropdownScheduleModal dropdownScheduleModal2"
                  />
                </div>
                <div className="sc-modal__selectedday">
                  {formik.values.custom.join(', ')}
                </div>
              </div>
            </Radio.Group>
          </div>

          <div className="sc-modal__select-date" style={{ marginBottom: 20 }}>
            <div className="sc-modal__date">
              <div className="sc-modal__label">CLASS START TIME*</div>
              <TimePicker
                format={format}
                onChange={(time, timeString) => {
                  formik.setFieldValue('startTime', time)
                }}
                value={formik.values.startTime}
                allowClear
                placeholder="Add start time"
                className="sc-modal__datepicker"
              />
              {formik.touched.startTime && formik.errors.startTime && (
                <div className="form__error">{formik.errors.startTime}</div>
              )}
            </div>
            <div className="sc-modal__date mgR-0" style={{ marginRight: 80 }}>
              <div className="sc-modal__label">CLASS END TIME*</div>
              <TimePicker
                format={format}
                onChange={(time, timeString) => {
                  formik.setFieldValue('endTime', time)
                }}
                value={formik.values.endTime}
                allowClear
                placeholder="Add end time"
                className="sc-modal__datepicker"
              />
              {formik.touched.endTime && formik.errors.endTime && (
                <div className="form__error">{formik.errors.endTime}</div>
              )}
            </div>
            <div className="sc-modal__date" style={{ width: 120 }}>
              <div className="sc-modal__label">BREAK</div>
              <Dropdown
                options={initialHourBreak}
                onChange={handleSelectHour}
                value={formik.values.hourBreak}
                placeholder="Add break time"
                className="dropdownScheduleModal"
              />
              {formik.touched.hourBreak && formik.errors.hourBreak && (
                <div className="form__error">{formik.errors.hourBreak}</div>
              )}
            </div>
          </div>

          <div className="sc-modal__select-date" style={{ marginBottom: 34 }}>
            <div className="sc-modal__date">
              <InputSmall
                text="Max class size (OPTIONAL)"
                iconLeft={images.admin.user}
                onChange={handleChangeMaxSizeClass}
                value={formik.values.max_size ? formik.values.max_size : ''}
                type="text"
                name="max_size"
                formik={formik}
                style={{ width: 148 }}
              />
            </div>
          </div>

          <div className="sc-modal__set-active">
            <div className="sc-modal__label switch_wrap">
              <span>SET TO ACTIVE?</span>{' '}
              <Switch
                checked={formik.values.active}
                onChange={(checked) => formik.setFieldValue('active', checked)}
              />
            </div>
            <div className="sc-modal__text">
              Setting this schedule to active would allow users to sign up for
              this particular dates. You can change this later except during the
              start to end date of this schedule.
            </div>
          </div>

          <div className="sc-modal__location">
            <div className="sc-modal__label">CLASS LOCATION*</div>
            <div className="sc-modal__location__option">
              <Radio.Group
                onChange={handleChangeVenue}
                value={formik.values.venue}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Radio
                  value="online"
                  style={{ marginRight: 50 }}
                  className="mgR-0"
                >
                  Online
                </Radio>
                <Radio value="in-campus" style={{ flex: 0.9 }}>
                  <span>In-campus</span>
                  <div style={{ marginTop: 8, marginLeft: 25 }}>
                    <textarea
                      className="add-course__textarea mgbt-0"
                      id="description"
                      name="description"
                      cols="30"
                      rows="3"
                      value={formik.values.location}
                      onChange={handleChangeLocation}
                      placeholder="Add a location address"
                    ></textarea>
                  </div>
                </Radio>
              </Radio.Group>
            </div>
          </div>
          {schedule && (
            <>
              <div className="sc-modal__zoom">Zoom Meeting (Optional)</div>
              <div className="sc-modal__set-active">
                <div
                  className="sc-modal__label switch_wrap"
                  style={{ width: '45%' }}
                >
                  <span style={{ marginBottom: 5, display: 'block' }}>
                    ZOOM INTEGRATION
                  </span>{' '}
                </div>
                <div className="sc-modal__zoom__text">
                  Schedule live lessons via Zoom Meetings or Zoom Webinars,
                  directly from within Hypeschools. Select the Zoom account you
                  would like to connect to for this course.
                </div>
                <Dropdown
                  options={mapListZoomsToDropdown(zooms)}
                  onChange={handleSelectZoomAccount}
                  value={formik.values.zoom}
                  className="dropdownScheduleModal"
                  placeholder="Zoom account"
                />
              </div>

              {/* <div className="sc-modal__set-active">
                <div
                  className="sc-modal__label switch_wrap"
                  style={{ width: '45%' }}
                >
                  <span style={{ marginBottom: 5, display: 'block' }}>
                    TYPE
                  </span>{' '}
                </div>

                <div className="mgR-100 mgR-0">
                  <Checkbox
                    checked={formik.values.meeting_type === 'meeting'}
                    onChange={(e) => {
                      const checked = e.target.checked
                      formik.setFieldValue(
                        'meeting_type',
                        e.target.checked ? 'meeting' : ''
                      )
                    }}
                  >
                    Meeting
                  </Checkbox>
                </div>
                <div className="mgR-100 mgR-0 mgT-10">
                  <Checkbox
                    checked={formik.values.meeting_type === 'webinar'}
                    onChange={(e) => {
                      const checked = e.target.checked
                      formik.setFieldValue(
                        'meeting_type',
                        e.target.checked ? 'webinar' : ''
                      )
                    }}
                  >
                    Webinar
                  </Checkbox>
                </div>
              </div> */}

              <div className="sc-modal__set-active">
                <div
                  className="sc-modal__label switch_wrap"
                  style={{ width: '45%' }}
                >
                  <span style={{ marginBottom: 5, display: 'block' }}>
                    MEETING SECURITY
                  </span>{' '}
                </div>
                <div className="sc-modal__zoom__text">
                  Set up a meeting password to prevent unwanted participants.
                  Course students logged in on Hypeschools do not have to enter
                  the password below.
                </div>
                <div className="sc-modal__label switch_wrap">
                  <span style={{ marginBottom: 5, display: 'block' }}>
                    Password
                  </span>{' '}
                </div>
                <input
                  value={formik.values.password}
                  className="input-small__input"
                  style={{ width: 148, padding: '0 12px', textAlign: 'left' }}
                  type="text"
                  name="password"
                  onChange={formik.handleChange}
                />
              </div>

              <div className="sc-modal__set-active">
                {/* <div
                  className="sc-modal__label switch_wrap"
                  style={{ width: '45%' }}
                >
                  <span style={{ marginBottom: 5, display: 'block' }}>
                    MEETING OPTIONS
                  </span>{' '}
                </div>

                <div className="mgR-100 mgR-0">
                  <Checkbox
                    checked={formik.values.meeting_option}
                    onChange={(e) => {
                      const checked = e.target.checked
                      formik.setFieldValue('meeting_option', checked)
                    }}
                  >
                    Enable joining before Host
                  </Checkbox>
                </div>
                <div className="mgR-100 mgR-0 mgT-10">
                  <Checkbox
                    checked={formik.values.meeting_option_2}
                    onChange={(e) => {
                      const checked = e.target.checked
                      formik.setFieldValue('meeting_option_2', checked)
                    }}
                  >
                    Enable waiting room
                  </Checkbox>
                </div> */}
                {schedule && (
                  <div className="sc-modal__set-active mgT-20">
                    <GenerateZoomLink
                      meeting={meeting}
                      schedule={schedule}
                      zoom={formik.values.zoom}
                      password={formik.values.password}
                      meeting_option={formik.values.meeting_option}
                      meeting_option_2={formik.values.meeting_option_2}
                      formik={formik}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="sc-modal__footer">
          <div>
            {schedule && (
              <BtnPrimary
                name="Delete"
                style={{
                  backgroundColor: 'unset',
                  color: '#D85025',
                  fontWeight: 'bold',
                  transition: 'all 0.2s',
                }}
                iconLeft={images.admin.trash_icon}
                type="button"
                handleClick={handleDeleteSchedule}
              />
            )}
          </div>
          <BtnPrimary
            name={`${schedule ? 'Save schedule' : 'Create schedule'}`}
            style={{
              backgroundColor: `${isValid ? '#00122A' : '#B3B8BF'}`,
              padding: 12,
              fontWeight: 'bold',
              transition: 'all 0.2s',
            }}
            iconLeft={renderIcon()}
          />
        </div>
      </form>
    </Modal>
  )
}

export default FormScheduleModal
